import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import strategy from "../Assets/img/strategy.png";
import group from "../Assets/images/icons/Group.png";
import swap from "../Assets/images/icons/Swap.png";
import medal from "../Assets/images/icons/Medal.png";
import Zigzagarrow from "../Assets/images/icons/Zigzag-arrow.png";
import Order from "../Assets/images/icons/Order.png";
import Waterdrop from "../Assets/images/icons/Water-drop.png";
function StrategiesPost() {
  return (
    <>
      <Col xl="3" lg="4" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={group} alt="Group" />
          </div>
          <div className="txt-pnl">
            <h4>no NotBonk Bot</h4>
            <p>
              We don’t have any cheeky Telegram Bot for buying NOTBONK Meme token
              or others.
            </p>
          </div>
        </Link>
      </Col>
      <Col xl="3" lg="4" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={swap} alt="Swap" />
          </div>
          <div className="txt-pnl">
            <h4>no NotBonk Swap</h4>
            <p>No mischief to build the nexus of degeneracy on Binance Smart Chain.</p>
          </div>
        </Link>
      </Col>
      <Col xl="3" lg="4" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={medal} alt="Medal" />
          </div>
          <div className="txt-pnl">
            <h4>no NotBonk Rewards</h4>
            <p>
            Can't earn giggle-worthy rewards by locking your NOTBONK.
            </p>
          </div>
        </Link>
      </Col>
      <Col xl="3" lg="4" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={Zigzagarrow} alt="Zigzag arrow" />
          </div>
          <div className="txt-pnl">
            <h4>Cross Chain Support</h4>
            <p>No playful cross-chain support available.</p>
          </div>
        </Link>
      </Col>
      {/* <Col xl="3" lg="4" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={Order} alt="Order" />
          </div>
          <div className="txt-pnl">
            <h4>Cross Chain Support</h4>
            <p>No playful cross-chain support available.</p>
          </div>
        </Link>
      </Col> */}
      <Col xl="3" lg="4" md="6" sm="12">
        <Link to="/" className="strategies-post">
          <div className="img-pnl">
            <img src={Waterdrop} alt="Waterdrop" />
          </div>
          <div className="txt-pnl">
            <h4>Not owned by anyone</h4>
            <p>
              A truly decentralized token, driven by the community and free from
              centralized control.
            </p>
          </div>
        </Link>
      </Col>
    </>
  );
}
export default StrategiesPost;
