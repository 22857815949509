import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Container, Button, Col } from 'react-bootstrap';
import NavBarDash from '../../Components/NavBarDash';
import { ethers } from 'ethers';
import { PulseLoader } from "react-spinners";
import ChatButton from '../../Components/ChatButton';
import UserContest from '../../ContextAPI/UserContest';
import Logo from '../../Assets/images/logo/bonk-logo.png';
import { AddNotBonkToken, AddUSDTToken, BuyTokenFunCall, BuyTokenFunCallWithPromo, Check_PromoCode, GetBalanceOfNBonk, GetBalanceOfUsdt, GetMinimumPurchase, GetTokenRate, Token_approvel, connectWithMetamask } from '../../Api';
import { useAccount,useNetwork, useSwitchNetwork, useConnect, useSignMessage, usePrepareContractWrite, useContractWrite } from 'wagmi';
import { ContractAddressICO, Get_TokenApprove_Abi, NotBonkAddress, TotalToken, USDTAddress, defaultChain, web3Value } from '../../Constants';
// import { ConnectModalInline, useAddress, ChainId, useNetwork, useChainId ,useBalance } from "@thirdweb-dev/react";
import { TokenAbi } from '../../Api/Tokenabi';
import { toast } from 'react-toastify';
import { Notifications } from '../../Components/Toast';

import { FaCopy } from 'react-icons/fa';


function Dashboard({ setShowFooter, setShowNavBar }) {
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork()
  const { SetPriceOfToken, PriceOftkoen, SetConnectedAddress,Promotioncode } = useContext(UserContest);
  const { connect, connectors } = useConnect();
  const { chain } = useNetwork()
  const { address, isConnecting, isDisconnected ,connector  } = useAccount();
  const [amountInUsdt, setAmountInUsdt] = useState('');
  const [balanceOfUsdt, setBalanceOfUsdt] = useState(0);
  const [balanceOfNbonk, setBalanceOfNbonk] = useState(0);
  const [progressbar, setProgressbar] = useState(0);
  const [PromoText, setPromoText] = useState()
  const [PromoSelected, setPromoSelected] = useState(false);
  const [MetamaskLoader, setMetamaskLoader] = useState(false)
  const [approveSuccess, setApproveSuccess] = useState(false); // new state variable
  const navigate = useNavigate();
  const [promoLoader, setpromoLoader] = useState(false)
  const [PromoPercentage, setPromoPercentage] = useState()
  const [PromoAppied, setPromoApplied] = useState(false)
  const [RemainingNotBonk, SetRemainingNotBonk] = useState()
  const [minumumPurchase, setMinumumPurchase] = useState()

  const {
    data: signMessageData,
    isSuccess: signMessageSuccess,
    signMessage,
  } = useSignMessage({
    message: "gm wagmi frens",
  });


 
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);

  const Check_Balance_of_tokens = (address) => {
    GetBalanceOfUsdt(address).then((result) => {
      setBalanceOfUsdt(result / 10 ** 18);
    }).catch(err => console.error(err));

    GetBalanceOfNBonk(address).then((result) => {
      setBalanceOfNbonk(result / web3Value);
    }).catch(err => console.error(err));

    GetTokenRate().then((item) => {
      SetPriceOfToken(item / web3Value);
    }).catch(err => console.error(err));
  }
  // const [connectedAddess, setconnectedAddess] = useState()


  useEffect(() => {
    const fetchData = () =>{
    GetMinimumPurchase().then((minimum) => {
      setMinumumPurchase(parseInt(minimum / web3Value))

    }).catch(() => {

    })
    GetBalanceOfNBonk(ContractAddressICO).then((result) => {
      SetRemainingNotBonk(result / web3Value);
    }).catch(() => {

    })



  }
    fetchData(); // Call immediately on mount

    const interval = setInterval(fetchData, 5000); // Call every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
 
  }, []);
  useEffect(()=>{
    const  fetchData = () =>{
    Check_Balance_of_tokens(address  )
    }
    // console.log(chain,' selected Chain ' )
    fetchData(); // Call immediately on mount

    const interval = setInterval(fetchData, 5000); // Call every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount

  },[address])



   
  const handlePayment = async () => {
    if (!address) {
      Notifications('error', 'Please Connect Wallet')
      return
    }

    if (amountInUsdt < 0) {
      Notifications('error', 'Amount cannot be negative')
      return
    }

    if (!amountInUsdt) {
      Notifications('error', 'Amount is Required')
      return
    }
    if (amountInUsdt < minumumPurchase) {
      Notifications('error', 'Amount is less than minumPurchase')
      return
    }
    if (parseFloat(amountInUsdt) > parseFloat(balanceOfUsdt)) {
      Notifications('error', 'Insufficient funds')
      return
    }



    setMetamaskLoader(true)
    async function handleTokenApproval() {
      try {

        await Token_approvel(USDTAddress, ContractAddressICO, amountInUsdt * (10 ** 18)).then(async()=>{

        
        
        // Wait for 10 seconds
        await new Promise(resolve => setTimeout(resolve, 10000));
    
        if (PromoSelected) {
          try {
            await BuyTokenFunCallWithPromo(amountInUsdt, PromoText);
            await Check_Balance_of_tokens(address);
            setMetamaskLoader(false);
            setAmountInUsdt(0);
            setPromoText('');
            setPromoPercentage(0);
            setPromoApplied(false);
            setPromoText('');
          } catch (error) {
            setMetamaskLoader(false);
          }
        } else {
          try {
            await BuyTokenFunCall(amountInUsdt);
            await Check_Balance_of_tokens(address);
            setMetamaskLoader(false);
            setAmountInUsdt(0);
            setPromoText('');
            setPromoPercentage(0);
            setPromoApplied(false);
          } catch (error) {
            setMetamaskLoader(false);
          }
        }

      }).catch(()=>{
        setMetamaskLoader(false);
      })
      } catch (error) {
        setMetamaskLoader(false);
      }
    }
    
    // Call the function to handle token approval
    handleTokenApproval();
    
  }
  const BuyPromoCheck = () => {
    setpromoLoader(true)
    Check_PromoCode(PromoText).then((percentage) => {
      // console.log(percentage, 'percentage')
      setPromoApplied(true)
      setPromoPercentage(parseInt(percentage))
      setpromoLoader(false)
    }).catch(() => {
      setPromoPercentage(0)
      setPromoApplied(true)
      setpromoLoader(false)
    })
  }


  const remainingNotBonk = parseInt(RemainingNotBonk);
  const totalToken = TotalToken;

  const percentage = (remainingNotBonk / totalToken) * 100;
  const formattedPercentage = percentage.toFixed(2);

  useEffect(() => {

    BuyPromoCheck()

  }, [PromoText])

  useEffect(() => {
    if (chain && chain.id !== defaultChain) {
      switchNetwork?.(defaultChain);
    }
  }, [chain, switchNetwork]);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <div className="lg" style={{ textAlign: '-webkit-center', width: '100%' }}>
                <div className="blue-post w-7 lg-f token-blnce-pnl align-items-center" style={{ width: '98%', maxWidth: '900px' }}>
                  <div style={{ textAlign: 'center' }}>
                    <img src={Logo} style={{ maxWidth: '250px' }} />
                  </div>
                  <div className="spacer-30" />
                  {/* <div style={{ textAlign: 'left' }}>
                    <h4>{`${formattedPercentage}% (${remainingNotBonk} NBONK Remaining)`}</h4>
                  </div> */}
                  <div className="progressbar-container_us" style={{ marginTop: '20px' }}>
                    <div className="progressbar-complete" style={{ width: `${formattedPercentage}%` }}>
                    </div>
                    <div className="progress_us">
                      <span style={{ fontWeight: 'bold' }}>{`${formattedPercentage}%`}</span>
                      <span>({remainingNotBonk} NBONK Remaining)</span>
                    </div>
                  </div>

                  <div className="my-4">
                    <div className="flex-div-sm">

                      <p className="brdr-p" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                        <FaCopy 
                          style={{cursor:'pointer'}}
                        onClick={async() => {
                          await connector?.watchAsset?.({ address:USDTAddress, symbol:'USDT', decimals:6 })
                        }} />{" "}
                        USDT Balance: ${parseFloat(balanceOfUsdt).toFixed(2) ?? 0}</p>
                      <p className="brdr-p" style={{ fontSize: 'large', fontWeight: 'bold' }}

                      >
                        <FaCopy

                        style={{cursor:'pointer'}}
                        onClick={async() => {
                          await connector?.watchAsset?.({ address:NotBonkAddress, symbol:'NBONK', decimals:18 })
                        }} 
                        />{" "}
                        NBONK Balance: {  balanceOfNbonk ?? 0}</p>
                    </div>
                    <div className="input-rounder flex-input-cntnr flex-div-sm" >
                      <input
                        value={amountInUsdt}
                        onChange={(e) => setAmountInUsdt(e.target.value)}
                        name="SendingPrice"

                        style={{ paddingLeft: '20px' }}
                        className="form-control1 my-input cal-input"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="Enter amount in USDT"
                        type="number"
                        required
                      />
                      <div>
                        <a
                          style={{ cursor: 'pointer' }}
                          id='buy-token-btn'
  
                          onClick={handlePayment}
                          className="reg-btn br-radous gradient mmt-3"
                        >
                          {
                            MetamaskLoader ?
                              <PulseLoader size={11} color="white" /> :
                              "BUY token now"
                          }

                        </a>
                      </div>
                    </div>
                    {PromoSelected &&
                      <>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <p style={{ fontSize: 'large', fontWeight: 'bold' }}>Enter Promo Code</p>
                        </div>
                        <div className="input-rounder flex-input-cntnr" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <input
                            name="Promo"
                            style={{ width: 'calc(100% - 150px)', paddingLeft: '20px' }}
                            className="form-control1 my-input"
                            aria-label="Amount (to the nearest dollar)"
                            placeholder="Enter Promo code Here"
                            type="text"
                            value={PromoText}
                            onChange={(e) => { setPromoText(e.target.value) }}
                            required

                          />
                          {/* <div>
                            <a

                              style={{ cursor: 'pointer' }}
                              onClick={BuyPromoCheck}
                              className="reg-btn br-radous gradient mmt-3">
                              {
                                promoLoader ?
                                  <PulseLoader size={11} color="white" /> :
                                  'Apply'
                              }

                            </a>
                          </div> */}
                        </div>
                      </>
                    }
                    {
                      !address &&  amountInUsdt && 
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <p style={{ color: 'red' }}>
                         Please connect your wallet
                        </p>
                      </div>
                    }
                    {
                      address && 
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      {
                        parseFloat(amountInUsdt) > parseFloat(balanceOfUsdt)
                        && amountInUsdt ? <p style={{ color: 'red' }}> Amount Cannot be Greater than Avaliable Balance </p>:<></>


                      }
                      {
                        parseFloat(amountInUsdt) < parseFloat(minumumPurchase)
                        && amountInUsdt ? <p style={{ color: 'red' }}> Minimum Purchase is {minumumPurchase} USDT </p>:<></>

                      }




                    </div>
                    }

                   

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Button
                        className="simple-link purple"
                        style={{ color: '#31A7FB' }}
                        onClick={() => {
                          setPromoSelected(!PromoSelected)
                          if (PromoSelected) {
                            setPromoText('')
                            setPromoPercentage(0)
                            setPromoApplied(false)
                          }
                        }}
                      >
                        {PromoSelected ? 'Remove Promo Code' : 'Add Promo Code'}


                      </Button>



                    </div>
                    {
                      PromoAppied && PromoText &&

                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {
                          PromoPercentage === 0 ?
                            <p style={{ color: 'red' }}> Not a valid Promo Code </p>

                            : <p style={{ color: 'red' }}> Promo Code Added </p>
                        }
                      </div>
                    }
                    <div className="mt-4" />
                    <Row>
                      <Col md={{ span: 10, offset: 1 }} sm="12">
                        <div className="new-grey-pnl">
                          <div className="text-left">
                            <h3 className="whitecolor text-uppercase">You Get:</h3>
                            <div className="spacer-10" />
                          </div>
                          <div className="text-center">
                            <h3 className="m-0 border-text">{amountInUsdt > 0 ? amountInUsdt : 0} USDT <span className="color mx-3">≈</span> {amountInUsdt > 0 ? parseFloat(amountInUsdt / PriceOftkoen).toFixed(2) : 0} NBONK</h3>
                          </div>
                          <div className="spacer-20" />
                          <div className="flex-div text-uppercase">
                            <p className="m-0">Tokens Ordered:</p>
                            <p className="m-0 purple-color">
                              <b className="notranslate"  >{amountInUsdt > 0 ? parseFloat(amountInUsdt / PriceOftkoen).toFixed(2) : 0} NBONK</b>
                            </p>
                          </div>


                          <div className="flex-div">
                            <p className="m-0">Promo Bonus:</p>
                            <p className="m-0 purple-color">
                              <b className="notranslate"> {((amountInUsdt > 0 ? parseFloat(amountInUsdt / PriceOftkoen).toFixed(2) : 0) * (PromoPercentage / 100)).toFixed(2)}    NBONK</b>
                            </p>
                          </div>

                          <div className="spacer-5" />
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4"></div>
                    {address ?
                      <>
                        <div style={{ textAlign: 'left' }}>
                          <p className="m-0">Your destination NOTBONK address</p>
                          <div className="full-div input-rounder">
                            <input
                              disabled
                              value={address}
                              placeholder="Connected Address Here"
                              name="NewPrice"
                              className="form-control my-input text-center"
                              aria-label="Amount (to the nearest dollar)"
                              required
                            />
                          </div>
                        </div>
                      </>
                      : <>

                      </>
                    }
                  </div>
                </div>
              </div>
            </Row>
          </Container>
          <div className="bottom-line">
            {Promotioncode?.name && (
              <p>
                Use promo <span>"{Promotioncode?.name}"</span> to get{" "}
                {Promotioncode?.bonus}% <span>bonus!</span>
              </p>
            )}
          </div>
        </Row>
      </section>
    </>
  );
}

export default Dashboard;
