import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Nav, Navbar, Container, NavDropdown, Dropdown, Button } from "react-bootstrap";
import Logo from "../Assets/images/logo/logo.svg";
import icondashboard1 from "../Assets/img/admin-icons/icon-dashboard-1.png";
import icondashboard2 from "../Assets/img/admin-icons/icon-dashboard-2.png";
import iconcart1 from "../Assets/img/admin-icons/icon-cart-1.png";
import iconcart2 from "../Assets/img/admin-icons/icon-cart-2.png";
import icontransaction1 from "../Assets/img/admin-icons/icon-trasaction-1.png";
import icontransaction2 from "../Assets/img/admin-icons/icon-trasaction-2.png";
import iconprofile1 from "../Assets/img/admin-icons/icon-profile-1.png";
import iconprofile2 from "../Assets/img/admin-icons/icon-profile-2.png";
import iconToken1 from "../Assets/img/admin-icons/icon-token-1.png";
import iconToken2 from "../Assets/img/admin-icons/icon-token-2.png";
import iconweb1 from "../Assets/img/admin-icons/icon-website-1.png";
import iconweb2 from "../Assets/img/admin-icons/icon-website-2.png";
import iconuser1 from "../Assets/img/admin-icons/icon-user-1.png";
import iconuser2 from "../Assets/img/admin-icons/icon-user-2.png";
import iconchain1 from "../Assets/img/admin-icons/icon-chain-1.png";
import iconchain2 from "../Assets/img/admin-icons/icon-chain-2.png";
import iconclock1 from "../Assets/img/admin-icons/icon-clock-1.png";
import iconclock2 from "../Assets/img/admin-icons/icon-clock-2.png";
import iconlogout1 from "../Assets/img/admin-icons/icon-logout-1.png";
import iconlogout2 from "../Assets/img/admin-icons/icon-logout-2.png";
import flag1 from "../Assets/img/flags/icon-australia-flag.png";
import flag2 from "../Assets/img/flags/icon-uae.png";
import flag3 from "../Assets/img/flags/icon-united-states-flag.png";
import UserContest from "../ContextAPI/UserContest";
import { getUserDetail } from "../Api";
import DisplayUserName from "./Displayname";
import { Google_Script_Url, Languages } from "../Constants";
import { FaWindowRestore } from "react-icons/fa";
import { useAccount, useDisconnect } from "wagmi";
import FloatingText from "./FloatingText";

function NavBarDash() {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { disconnect } = useDisconnect();
  const navigate = useNavigate()
  const { CurrentStage,
    TotalSaleInStage, Promotioncode, OverAllSaleToken, SetPromocode, Promocode, SetUserDetail, UserDetail } = useContext(UserContest);
  const [language, setlanguage] = useState()

  const [selectedLanguage, setSelectedLanguage] = useState('en')


  useEffect(() => {
    try {
      const cookie = document?.cookie?.split(";")?.find((cookie) => cookie?.includes("googtrans"));

      if (cookie) {
        const languageFromCookie = cookie.split("/")[2];

        if (languageFromCookie) {
          setSelectedLanguage(languageFromCookie);
        }
      }
    } catch (error) {
      // console.error("Error while processing cookie:", error);
    }
  }, []);

  function removeCookie2(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  const removeCookie3 = (name, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
  };

  function setCookie2(key, value, expiry) {
    // console.log("mazak ha key", key)
    removeCookie2(key)
    return new Promise((resolve) => {

      setTimeout(() => {
        var existingCookie = document.cookie;
        var cookies = existingCookie?.split(";")[0];
        // console.log("mazak ha existing existingCookie?split[0]", existingCookie?.split(";")[0])
        // console.log("mazak ha existing Cookie", existingCookie)
        // console.log("mazak ha existing split ", cookies)

        // Check if the key already exists in the cookies
        var updatedCookie = false;
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(key + "=") === 0) {
            updatedCookie = true;
            // Update the existing cookie value
            var cookieValue = cookie.substring(key.length + 1);
            document.cookie = key + "=" + value + "; expires=" + expiry + "; path=/";
            break;
          }
        }

        // console.log("mazak ha updated updatedCookie", updatedCookie)

        if (!updatedCookie) {
          // Create a new cookie if the key doesn't exist
          document.cookie = key + "=" + value + "; expires=" + expiry + "; path=/";
        }
        resolve();
      }, 1000);
    });
  }
  function setCookie3(key, value, expiry, domain) {
    // console.log(key, value, expiry,domain )
    removeCookie3(key, domain)
    return new Promise((resolve) => {


      setTimeout(() => {
        var existingCookie = document.cookie;
        var cookies = existingCookie.split(";");

        var updatedCookie = false;

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(key + "=") === 0) {
            updatedCookie = true;
            var cookieValue = cookie.substring(key.length + 1);
            document.cookie = key + "=" + value + "; expires=" + expiry + "; path=/; domain=" + domain;
            break;
          }
        }

        if (!updatedCookie) {
          document.cookie = key + "=" + value + "; expires=" + expiry + "; path=/; domain=" + domain;
        }

        resolve();
      }, 1000);
    });
  }

  const googleTranslateElementInit = () => {
    const element = new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,ar,zh-CN,fr,de,id,it,ko,pt,es,th,vi",
        layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    // console.log(document.documentElement.lang,"language")

    const interval = setInterval(() => {
      if (
        document.documentElement.lang === localStorage.getItem("Language") ||
        (document.documentElement.lang === "en" &&
          localStorage.getItem("Language") === "lo")
      ) {
        clearInterval(interval);
      }
    }, [1000]);
  }, []);

  // useEffect(() => {
  //   // console.log(" googleTranslateElementInit true")
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     Google_Script_Url
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;

  //   // console.log(" googleTranslateElementInit false")
  // }, [language]);






  return (
    <>

      <Navbar expand="lg" className="bg-body-tertiary dashboard-navbar">
        <Container fluid>
          <Navbar.Brand href="/"        onClick={async () => {
                  disconnect();
                  //  window.location.reload()
                }}>
         
            <img src={Logo} alt="Logo" />
        
          </Navbar.Brand>
          {/* <div id="google_translate_element" /> */}

          <div className="mobile-view-flex">


        

            {!isDisconnected && !isConnecting ? (
              <Button
                style={{
                  fontSize: "16px",
                }}
                className="reg-btn br-radous gradient mmt-3"
                onClick={async () => {
                  disconnect();
                   window.location.reload()
                }}>
                Disconnect
              </Button>
            ) : (

              <w3m-connect-button />

            )}



            <Navbar.Toggle aria-controls="navbarScroll" />
          </div>
   
        </Container>
      </Navbar>
      

    </>
  );
}
export default NavBarDash;
