import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Frequentlyquestion from "../Components/Frequentlyquestion";
import coinbase from "../Assets/images/logo/coinbase.png";
import bnb from "../Assets/images/logo/bnb.png";
import crypto from "../Assets/images/logo/crypto.png";
import uniswap from "../Assets/img/icon-uniswap.png";

import iconjoin from "../Assets/img/icon-join.png";
import iconlink from "../Assets/img/icon-link.png";
import iconbuy from "../Assets/img/icon-buy.png";

import Header from "../Components/Header";
import Tokenomics from "../Components/Tokenomics";
import { PresaleButton, PresalesButton } from "../Components/Buttons";
import StrategiesPost from "../Components/StrategiesPost";
import Whoweare from "../Components/Whoweare";
import FishList from "../Components/FishList";
import UserContest from "../ContextAPI/UserContest";
import { Signup } from "../Api";
import { useAccount, useDisconnect } from "wagmi";
import { FaCopy } from "react-icons/fa";
import { Notifications } from "../Components/Toast";
import { NotBonkAddress, SWAPTOEKNADDRESS } from "../Constants";
function Home({
  setSignupShow,
  RewardSectionRef,
  TokenSectionRef,
  RoadSectionRef,
  FAQeSectionRef,
  SignupShow,
  featuredSectionRef,
  setShowNavBar,
  setShowFooter,
}) {
  const { CurrentStage, TotalSaleInStage } = useContext(UserContest);
  useEffect(() => {
    setShowNavBar(true);
    setShowFooter(true);
  }, []);
  const copyToClipboard = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        Notifications("success", "Address copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy address: ", err);
      });
  };
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected } = useAccount();
  // RewardSectionRef={RewardSectionRef}
  // TokenSectionRef={TokenSectionRef}
  // RoadSectionRef={RoadSectionRef}
  // WhiteSectionRef={WhiteSectionRef}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      {/* Header Starts Here */}

      <Header setSignupShow={setSignupShow} SignupShow={SignupShow} />
      {/* Header Starts Here */}
      {/* Uniswap Starts Here */}
      <section
        ref={featuredSectionRef}
        className="container-fluid who-we-are-pnl"
      >
        <Row>
          <Container className="zindex">
            <Row
              onClick={() => {
                setSignupShow(true);
              }}
            >
              <Col
                xl={{ span: 4, order: 2 }}
                lg={{ span: 4, order: 2 }}
                md={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
              >
                <img src={uniswap} alt="uniswap" />
              </Col>
              <Col
                xl={{ span: 8, order: 1 }}
                lg={{ span: 8, order: 1 }}
                md={{ span: 12, order: 2 }}
                sm={{ span: 12, order: 2 }}
              >
                <div className="spacer-40" />
                <h2 className="text-uppercase font-weight-normal">
                  Available On <span>UniSWAP</span>
                </h2>
                <p>NOTBONK will be LIVE on UNISWAP from 1st September.</p>
                <p><b className="color">NBONK:</b> { NotBonkAddress }  <FaCopy
              style={{ cursor: "pointer" }}
              onClick={() => {
                copyToClipboard(NotBonkAddress);
              }}
            />  </p>
                <p><b className="color">Pair Address:</b> {SWAPTOEKNADDRESS} 
                <FaCopy
              style={{ cursor: "pointer" }}
              onClick={() => {
                copyToClipboard(SWAPTOEKNADDRESS);
              }}
            /> 
                </p>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Uniswap Ends Here */}
    

      {/* About Starts Here */}
      <section
        ref={featuredSectionRef}
        className="container-fluid who-we-are-pnl"
      >
        <Whoweare setSignupShow={setSignupShow} />
      </section>
      {/* About Ends Here */}

        {/* How to Buy Starts Here */}
        <section ref={RewardSectionRef} className="container-fluid ">
        <Row
          onClick={() => {
            setSignupShow(true);
          }}
          ref={RewardSectionRef}
        >
          <Container>
            <Row>
              <Col xl="12">
                <h2 className="text-uppercase text-center font-weight-normal">
                  How to <span>Buy</span>
                </h2>
                <ul className="buy-list">
                  <li>
                    <img src={iconjoin} alt="icon join" />
                    <h6>
                      Join <span>Pre-sale</span>
                    </h6>
                  </li>
                  <li>
                    <img src={iconlink} alt="icon link" />
                    <h6>
                      connect <span>wallet</span>
                    </h6>
                  </li>
                  <li>
                    <img src={iconbuy} alt="icon buy" />
                    <h6>
                      purchase <span>NOTBONK</span>
                    </h6>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* How to Buy Ends Here */}

      {/* Use Starts Here */}
      <section
        ref={RewardSectionRef}
        className="container-fluid integration-count-pnl"
      >
        <Row
          onClick={() => {
            setSignupShow(true);
          }}
          ref={RewardSectionRef}
        >
          <Container>
            <Row>
              <Col xl="12">
                <h2 className="text-uppercase font-weight-normal">
                  use <span>Notbonk</span>
                </h2>
                <p className="m-0">Zero integrations & loving it!</p>
                <ul className="integration-list">
                  <li>
                    <Link href="#">
                      <h6>Exchanges</h6>
                      <span>03</span>
                    </Link>
                  </li>
                  <li>
                    <h6>DeFi Integrations</h6>
                    <span>Not Any</span>
                  </li>
                  <li>
                    <Link href="#">
                      <h6>Gaming</h6>
                      <span>Not Even a Single</span>
                    </Link>
                  </li>
                  <li>
                    <h6>NFT</h6>
                    <span>Zero Again</span>
                  </li>
                  <li>
                    <Link href="#">
                      <h6>Developer</h6>
                      <span>Same</span>
                    </Link>
                  </li>
                  <li>
                    <h6>Wallet</h6>
                    <span>No Integration</span>
                  </li>
                  <li>
                    <Link href="#">
                      <h6>Featured</h6>
                      <span>Zero</span>
                    </Link>
                  </li>
                  <li>
                    <h6>Social</h6>
                    <span>Still The Same</span>
                  </li>
                  <li>
                    <Link href="#">
                      <h6>Transfer Tool</h6>
                      <span> A Big 0</span>
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Use Ends Here */}

      {/* Get Starts Here */}
      {/* <section className="container-fluid text-center">
        <Row
          onClick={() => {
            setSignupShow(true);
          }}
          ref={RewardSectionRef}
        >
          <Container>
            <Row>
              <Col xl="12">
                <h2 className="text-uppercase font-weight-normal">
                  Get <span>Notbonk</span>
                </h2>
                <p className="m-0">
                  If you are ready to join the Laughter Train, Take Action and
                  get your NOTBONK Meme token!
                </p>
                <p>
                  (Once our Chuckle Champion NOTBONK Meme token is Launched you
                  can get it through these centralized exchanges)
                </p>
                <ul className="coin-list">
                  <li><Link href="#"><img src={coinbase} alt="coinbase"/> Coinbase</Link></li>
                  <li><Link href="#"><img src={bnb} alt="bnb"/> Binance</Link></li>
                  <li><Link href="#"><img src={crypto} alt="crypto"/> Crypto.com</Link></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
      </section> */}
      {/* Get Ends Here */}

      {/* Vrutal Tokenomics Starts Here */}
      <section
        onClick={() => {
          setSignupShow(true);
        }}
        ref={TokenSectionRef}
        className="container-fluid text-center"
      >
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12">
                <Tokenomics />
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Vrutal Tokenomics Ends Here */}

      {/* Strategies Starts Here */}
      <section className="container-fluid text-center">
        <Row
          onClick={() => {
            setSignupShow(true);
          }}
          ref={RoadSectionRef}
        >
          <Container>
            <Row>
              <Col xl="12">
                <h2 className="text-uppercase font-weight-normal">
                  Key <span>Projects</span>
                </h2>
                <div className="spacer-50" />
              </Col>
            </Row>
            <Row>
              {/*Strategies Post */}
              <StrategiesPost />
              {/*Strategies Post */}
            </Row>
          </Container>
        </Row>
      </section>
      {/* Strategies Ends Here */}

      {/* Frequently Question Panel Starts Here */}
      <section ref={FAQeSectionRef} className="container-fluid text-center">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12">
                <h2 className="text-uppercase font-weight-normal">
                  Frequently Asked <span>Questions</span>
                </h2>
              </Col>
              <Col xl="12" lg="12">
                <Frequentlyquestion />
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Frequently Question Panel Ends Here */}
    </>
  );
}
export default Home;
