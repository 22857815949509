import Web3 from "web3";

// export  const Base_Url =  'http://localhost:9854/v1/'
// export  const Base_Url =  'http://192.168.1.24:9854/v1/'
// http://192.168.1.15:9854/
// https://algtapi.notbonk.xyz/
export const Base_Url = 'https://be.notbonk.xyz/v1/'
export const Base_Url_V1 = 'https://be.notbonk.xyz/'

// export const Base_Url = 'http://localhost:9854/v1/'
export const FE_URL = 'https://notbonk.xyz/'
export const CommasRegex = /\B(?=(\d{3})+(?!\d))/g

// export  const Base_Url =  'http://localhost:9854/v1/'
export const Google_Script_Url = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'

export const NumberRegex = /^\d*$/
export const videoUrl = 'https://www.youtube.com/embed/VJLXPgh_44g'
export const emailInputRegex = /^[a-z_A-Z0-9.@-]*$/
export const TextInputRegex = /^[a-zA-Z0-9.]*$/

// export  const Base_Url =  'http://localhost:9854/v1/'
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/;
export const ethereumAddressRegex = /^0x[0-9a-fA-F]{40}$/;
export const projectId = 'b7886983b85653378b1c43f0172c978e'
export const nameRegex = /^[a-zA-Z]{3,15}[a-zA-Z\s]*$/;
export const NowPaymentBaseUrl = 'https://api.nowpayments.io/'
export const ReferralLink = `${FE_URL}invite?ref=`
export const colorWhite = "white"
export const colorRed = 'red'
export const WhitePaperLink = `${FE_URL}White_Paper_1.0.pdf`
export const BannerVideo = `${FE_URL}banner.gif`
export const twitterIcon = 'https://x.com/Notbonkcoin'
export const DiscordIcon = 'https://discord.gg/PCpE9zqk'
export const linkedinIcon = 'https://www.linkedin.com/company/Notbonkcoin/'
export const facebookIcon = 'https://www.facebook.com/Notbonkcoin'
export const InstagramUrl = 'https://www.instagram.com/notbonkcoin/'
export const teleGramUrl = 'http://t.me/Notbonkcoin'
export const WalletIcon = 'https://avatars.githubusercontent.com/u/37784886'
export const GiveawayIcon = 'https://gleam.io/56BZM/win-250k-with-Notbonkcoin'


const svgUSDT = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAhCAYAAAC4JqlRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAVNSURBVHgBrVhdTFtVHP/f2wKCKYK6DVtjOmFQxqLMDAbqIga2R8N8MHuZJX5EEzFjBoj4IMUHZ4BkLLJEE41lxof5IIsPPmwQMRin7WaGGeHistFoQBdx4FAYH+3x/M7t7U7vxyjbfsnNvT1fv//3OacKbRD+lrN+V3ZWpTux5mekFKBNITa/prpjWSurF7S+2thG1lMyGRTgpIlsd5ATNREj/zrDY4xoRF2Nd2UijLIeMWW5OvmCTXQb4PPC6wniKEBZW+QQXyHERxTQnWGelETXZHdNH2UqQFl79Cgx1kJ3EUxRQr92V3WZ21XKkNxzj4ua9/roxGsBcgL69u96kHyF2ZY+hbFQaVvkM3O7W/5R2h7ttCN/8ekt9OY+LxfCTbdCdbFHPNNzy9R/ZoYGz82mC8FjiXPEZEu4jI+S1midSiwsT4DWn7xcRgdqN1OOW6XI5QXq+HKKE6zYCoD2cu+93AI51FBRKL5HJ/+hlTUmC1G36cmXvpv94dNY8rcOHnRT/OWXyT9/PUABbx4t3IjTh6en6cT3V9MI6yv0+Bwenyezxd557hHxrc0s0sGPNLGGhNhkT/VWfIgYCLRFmmRyAAuAHFo1Hh23kEPL48Ft4jEDYxv7xmlhKS7WaN7nMw/xb9M5dQG4gTrlXgQSHqENlx4+NSPgzU19lz+UZ+mH5m8MXBLfQW6R6kc9af2c+BDeLuF7hdIC73iwhPJz3SKQhsfnLMQnm7fTgZrNqTbEiJ2/jVipLs4n3/055qAsQiyoKiXq5Nb6HQXCvJjcf2aazIDJ0Y/FoCEejEU8GH6XMcDdAVfAAmYrJEjlyivKM3Jjw/ZC8Y5cvm5ZDAuAXPtjUWQDgg8P3CSEryi0zAH5V+f/su1XFFbp5nngp5tWo3Kf7k9zDgOePD1rvQU5IkuMyEaMBNqj5ITIlQUeB0W0u8Rj6lEfd5t3t0AyoOxyHXUAGuXnuujU4QoaujjPrfEfTV9bESRO0KaXUoKb4HcsbXaRD/KDH2upOAju2ZI2/v2vf7PUBHktCG6GowCyiWUgveqPjIn8Rs0v5+/9uzal6kLVuz9b5nlsiA2oPAZidtI+XJhjKxSI8IYg0BapCoFgHaBhhzUQjTpxfcmiUIyXf+WC3DIxo/urqthjWQjFabjjMbErmjHBMwNYsJIIawFackwKjAvAWGJMbjPSz04TaA3A9xAGlsDTwfPfyPGJmUXLvOer9Ko6GJ018TNRiEbkxsHzs46FA5FuFKcjL2yl6HtPiAelFn7vODllCV6sYWSWOVPA7cK2+MBTrzbybbEIjSilOVmKKJ/Y21EP5PKKRQbP/S2I9pTdJ9r6T8/QW19cobHf/00jENv5K6WirA+MXqVvxq7d7FSwI9YcVnVJ4sfkiSifqAMispusux3I5d1RWO3GmmUcSrNR1s27aYJRl87NofXUhuVsgAtQXg1XnGqpsD1mwTpDfLMymx2a9/MNDXEC1+j7xXKa9pd6qsP6ZxKB1rN1THF9Ky+E6NWLTnbqmDV0cc62PhgAafNer9Ac43AY0UyBqbD4s1pv7UiaALoQP4X46TXtbKC7oUQq0cs873+xJUeK+pL1Q+PpbNFckLMurXd3KPXbvAgXIsyFCJKNZoh2WMVp49G6q4S/B0b/tPg8iWP8KJZ29rC9F9hZwgBOxnYBJ+Zx4TSbOuBE7igAUNb6Ix+sdt6VmxHjN6Ne+5uR6jQLExR3fCf32QDdJnA3VFzxnU7kQGa347f5JXVNDTGVn54yuB0Lod2JsPbBHd6OnYRJrKqV/DiV9v8AY0pMjSc2/P/A/6gTfxx3xxG3AAAAAElFTkSuQmCC'

export const Api_key = 'QXHC0G8-R2B4WAJ-GV2NKQ9-8EK9FB6'

export const countryUrl = "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
export const CurrenicesArray = [
  {
    label: 'btc',
    image: 'https://nowpayments.io/images/coins/btc.svg',
  },
  {
    label: 'eth',
    image: 'https://nowpayments.io/images/coins/eth.svg',
  },
  {
    label: 'sol',
    image: 'https://nowpayments.io/images/coins/sol.svg',
  },
  {
    label: 'doge',
    image: 'https://nowpayments.io/images/coins/doge.svg',
  },
  {
    label: 'shib',
    image: 'https://nowpayments.io/images/coins/shib.svg',
  },
  {
    label: 'bnb',
    image: 'https://nowpayments.io/images/coins/bnbbsc.svg',
  },
  {
    label: 'ltc',
    image: 'https://nowpayments.io/images/coins/ltc.svg',
  },
  {
    label: 'bch',
    image: 'https://nowpayments.io/images/coins/bch.svg',
  },
  {
    label: 'usdtmatic',
    image: svgUSDT,
  },
  {
    label: 'usdtsol',
    image: svgUSDT,
  },
  {
    label: 'usdterc20',
    image: svgUSDT,
  },
  {
    label: 'usdttrc20',
    image: svgUSDT,
  },
  {
    label: 'usdtbsc',
    image: svgUSDT,
  },
]

export const CurrencyImageUrl = 'https://nowpayments.io'
export const CurrenciesArray = [
  { dataIcon: "bitcoin-btc-logo", dataLabel: "BTC (BTC)", value: "btc" },
  { dataIcon: "ethereum-eth-logo", dataLabel: "ETH (ETH/ERC-20)", value: "eth" },
  { dataIcon: "binance-usd-busd-logo", dataLabel: "BUSD (BSC/ BEP20)", value: "busd" },
  { dataIcon: "tether-usdt-logo", dataLabel: "USDT (ETH/ERC-20)", value: "usdt" },
  { dataIcon: "bnb-bsc-logo", dataLabel: "BNB (BSC/BEP20)", value: "bnbbsc" },
  { dataIcon: "bitcoin-cash-bch-logo", dataLabel: "BCH (BCH)", value: "bch" },
  { dataIcon: "litecoin-ltc-logo", dataLabel: "LTC (LTC)", value: "ltc" },
  { dataIcon: "dogecoin-doge-logo", dataLabel: "DOGE (DOGE)", value: "doge" },
  { dataIcon: "solana-sol-logo", dataLabel: "SOL (SOL)", value: "sol" },
  { dataIcon: "tron-trx-logo", dataLabel: "TRX (TRX/TRC-20)", value: "trx" },
  { dataIcon: "uniswap-uni-logo", dataLabel: "UNI (ETH/ERC-20)", value: "uni" },
  { dataIcon: "shiba-inu-shib-logo", dataLabel: "SHIB (ETH/ERC-20)", value: "shib" },
  { dataIcon: "elrond-egld-egld-logo", dataLabel: "EGLD (EGLD)", value: "egld" },
  { dataIcon: "multi-collateral-dai-dai-logo", dataLabel: "DAI (ETH/ERC-20)", value: "dai" },
  { dataIcon: "stellar-xlm-logo", dataLabel: "XLM (XLM)", value: "xlm" },
  { dataIcon: "dash-dash-logo", dataLabel: "DASH (DASH)", value: "dash" }
  // Add more options here if needed
];


// 3000/


export const TokenAddresses = {
  USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  USDC: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
}

export const Languages = [
  {
    image: "https://flagpedia.net/data/flags/normal/sa.png",
    value: "Arabic",
    symbol: "ar"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/cn.png",
    value: "Chinese",
    symbol: "zh-CN"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/nl.png",
    value: "Dutch",
    symbol: "nl"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/fr.png",
    value: "French",
    symbol: "fr"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/de.png",
    value: "German",
    symbol: "de"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/id.png",
    value: "Indonesian",
    symbol: "id"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/it.png",
    value: "Italian",
    symbol: "it"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/kr.png",
    value: "Korean",
    symbol: "ko"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/pt.png",
    value: "Portuguese",
    symbol: "pt"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/es.png",
    value: "Spanish",
    symbol: "es"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/th.png",
    value: "Thai",
    symbol: "th"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/vn.png",
    value: "Vietnamese",
    symbol: "vi"
  },
  {
    image: "https://flagpedia.net/data/flags/normal/gb.png",
    value: "English",
    symbol: "en"
  }
];

export const NoStageActive = {
  "id": 71,
  "level": "No Stage Active",
  "price_of_token": "0",
  "total_limit": "0",
  "currently_active": true,
  "created_at": "2024-01-10T10:45:04.738Z",
  "futuretokenprice": "0",
  "minimumpurchase": "0",
  "startdate": "undefined",
  "enddate": "2024-01-18T18:04",
  "maximumpurchase": "0"
}



export const usdterc20 = {
  name: 'usdterc20',
  label: 'USDTERC20 (Ethereum)'
}
export const usdtbsc = {
  name: 'usdtbsc',
  label: 'USDTBSC (Binance)'
}
export const usdttrc20 = {
  name: 'usdttrc20',
  label: 'USDTTRC20 (Tron)'
}




export const BSC_CHAIN_ID = '0x38'; // Hexadecimal value for BSC Mainnet (56)
export const BSC_PARAMS = {
  chainId: BSC_CHAIN_ID,
  chainName: 'Binance Smart Chain',
  nativeCurrency: {
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: ['https://bsc-dataseed.binance.org/'],
  blockExplorerUrls: ['https://bscscan.com/'],
};


export const web3_custom = new Web3(
  new Web3.providers.HttpProvider(
    "https://bsc-dataseed.binance.org/"
  )
);

export const Get_TokenBalabce_abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export const Get_TokenApprove_Abi = [
  
  { "constant": false, "inputs": [{ "internalType": "address", "name": "spender", "type": "address" }, 
  { "internalType": "uint256", "name": "amount", "type": "uint256" }],
   "name": "approve", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "payable": false, "stateMutability": "nonpayable", "type": "function" }
];

export const web3Value = 10 ** 18

export const NotBonkAddress = "0xfcd02B895898BaC52976e657813a45676CfBd658"
export const USDTAddress = "0x55d398326f99059fF775485246999027B3197955"

export const ContractAddressICO = "0x2C612483867D9d8E212B9140c8A0f0865Ef0A5dd"

export const SWAPTOEKNADDRESS = "0x1c5B57a475805f21C88eB09689860559D9a99f82"

export const TotalToken = 5000000000000;
export const defaultChain = 56;