import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Logo from "../Assets/images/logo/footer-logo.svg";
import git from "../Assets/img/Icons/icon-git.png";
import twitter from "../Assets/img/Icons/Twitter.png";
import Discord from "../Assets/img/Icons/Discord.png";
import gitt from "../Assets/img/Icons/icon-git.png";
import telegram from "../Assets/img/Icons/Telegram.png";
import facebook from "../Assets/img/Icons/Facebook.png";
import instagram from "../Assets/img/Icons/Instagram.png";


import linkedin from "../Assets/img/Icons/Linkindin.png";




import {
  DiscordIcon,
  InstagramUrl,
  WhitePaperLink,
  facebookIcon,
  linkedinIcon,
  teleGramUrl,
  twitterIcon,
} from "../Constants";

function Footer({
  scrollToFeatured,
  RewardToFeatured,
  TokenToFeatured,
  FaqToFeatured,
  RoadToFeatured,
}) {
  return (
    <>
      <footer className="container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </Col>
              <Col xl="12" lg="12" md="12" sm="12">
                <ul className="footer-list">
                <li>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      to={"/"}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        scrollToFeatured()
                      }}
                      to={"/"}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to={"/"} onClick={() => RoadToFeatured()}>
                      Key Projects
                    </Link>
                  </li>
               
                  <li>
                    <Link to={WhitePaperLink} target="_blank">
                      white paper
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => TokenToFeatured()} to={"/"}>
                      tokenomics
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={() => FaqToFeatured()}>FAQs</Link>
                  </li>
                  <li>
                    <Link to="/TermsConditions">terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                </ul>
                <h6 className="letter-spacing">Follow us</h6>
                <ul className="footer-social-list footer-icon">
                  <li>
                    <Link target="_blank" to={twitterIcon}>
                      <img src={twitter} alt="twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={DiscordIcon}>
                      <img src={Discord} alt="Discord" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link target="_blank" to={InstagramUrl}>
                    <img src={gitt} alt="Git" />
                    </Link>
                  </li> */}
                  <li>
                    <Link target="_blank" to={teleGramUrl}>
                    <img src={telegram} alt="telegram" />
                    </Link>
                  </li>

                  <li>
                    <Link target="_blank" to={facebookIcon}>
                    <img src={facebook} alt="telegram" />
                    </Link>
                  </li>

                  <li>
                    <Link target="_blank" to={InstagramUrl}>
                    <img src={instagram} alt="telegram" />
                    </Link>
                  </li>

                  <li>
                    <Link target="_blank" to={linkedinIcon}>
                    <img src={linkedin} alt="telegram" />
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
      </footer>
    </>
  );
}
export default Footer;
