import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Nav,
  Navbar,
  Button,
  Dropdown,
  Container,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import Logo from "../Assets/images/logo/bonk-logo.png";
import User from "../Assets/images/icons/icon-user.png";
import flag1 from "../Assets/img/flags/icon-australia-flag.png";
import flag2 from "../Assets/img/flags/icon-uae.png";
import flag3 from "../Assets/img/flags/icon-united-states-flag.png";
import Emailbox from "../Assets/img/Icons/icon-envelpoe-1.png";
import popBanner from "../Assets/images/popBanner.png";
import {
  Check_PromoCode,
  GetAllAvaliableCurrencies,
  GetPromoCode,
  GetTokenRate,
  Get_latestPromoCode,
  GraphTotal,
  Login,
  ResetProfilePassword,
  SignUpWithEmail,
  Signup,
  ValidateOTPonLogin,
  forgetPasswordApi,
  getBonusTokenCountWithOutStage,
  getDetailofUserwithWallet,
  getTokenBonusCount,
} from "../Api";
import { Toast } from "bootstrap";
import { ToastContainer, toast } from "react-toastify";
import UserContest from "../ContextAPI/UserContest";
import {
  CurrenicesArray,
  Google_Script_Url,
  Languages,
  WhitePaperLink,
  colorRed,
  colorWhite,
  countryUrl,
  emailRegex,
  nameRegex,
  NumberRegex,
  passwordRegex,
  emailInputRegex,
  usdterc20,
  usdtbsc,
  usdttrc20,
  web3Value

} from "../Constants";
import { Notifications } from "./Toast";
// import iconerror from '../Assets'
import iconerror from "../Assets/img/admin-icons/icon-error.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { PulseLoader } from "react-spinners";

import "../google.scss";

import { CountrySelector, PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import Select from "react-select";
import WalletTransaction from "../Pages/AdminPanel/WalletTransactions";
import { useWeb3ModalEvents } from "@web3modal/wagmi/react";

// import { useAccount   , useDisconnect } from 'wagmi'
import { useAccount, useDisconnect } from "wagmi";
import OTPInput from "react-otp-input";

function NavBar({
  setSignupShow,
  setctaModal,
  ctaModal,
  setloginShow,
  loginShow,
  forgetPassword,
  SignupShow,
  scrollToFeatured,
  RewardToFeatured,
  TokenToFeatured,
  FaqToFeatured,
  RoadToFeatured,
}) {
  const {
    CurrentStage,
    NavBaRSignUp,
    SetNavBaRSignUp,

    TotalSaleInStage,
    OverAllSaleToken,
    SetSelectedCurrency,
    SetSelectedvalue,
    SetshowTransaction,
    SetPromocode,
    Promocode,
    Promotioncode,
    SetPromotioncode,
    SelectedCurrency,
    Selectedvalue,
    SetUserDetail,
    UserDetail,
    SetPriceOfToken,
    PriceOftkoen
  } = useContext(UserContest);

  const navigate = useNavigate();
  const [UserId, setUserId] = useState();
  const { disconnect } = useDisconnect();

  const events = useWeb3ModalEvents();
  const [BonusToken, setBonusToken] = useState();

  const location = useLocation();
  // const address = ""
  const { address, isConnecting, isDisconnected } = useAccount();
  const [forgetPasswrodLoader, setforgetPasswrodLoader] = useState(false);

  const [emailloginShow, setemailloginShow] = useState(false);
  const [PasswordModal, setPasswordModal] = useState(false);
  const [PasswordOTP, setPasswordOTP] = useState(false);
  const [FactorLoader, setFactorLoader] = useState(false);
  const [tempToken, setTemptToken] = useState(false);

   const [bannerModal , setBannerModal] =useState(true)
  const [dialedCode, setDialedCode] = useState();
  const [CurrentCountry, setCurrentCountry] = useState();
  const [SelectedCountryFlag, setSelectedCountryFlag] = useState();
  const [EmailReset, setEmailReset] = useState("");
  const [language, setlanguage] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [rememberMe, setRememberMe] = useState(false);
  const [userDeatilofaddress, setuserDeatilofaddress] = useState();

  const [OtpLoader, setOtpLoader] = useState(false)
  const [LoginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const cokeisString = {
    rememberedEmail: "rememberedEmail",
    rememberedPassword: "rememberedPassword",
    rememberMe: "rememberMe",
  };

  useEffect(() => {
    // Load saved data from cookies on component mount
    const savedEmail = getCookie("rememberedEmail");
    const savedPassword = getCookie("rememberedPassword");
    const savedRememberMe = getCookie("rememberMe");
    // console.log(savedEmail, savedPassword, savedRememberMe, "savedRememberMe");

    if (savedRememberMe) {
      setLoginData((prev) => ({
        ...prev,
        email: savedEmail || "",
        password: savedPassword || "",
      }));
      setRememberMe(true);
    }
  }, [emailloginShow]);

  const handleLogin = () => {
    // Your login logic here

    // If "Remember Me" is checked, save email and password to cookies
    if (rememberMe) {
      setCookie(cokeisString.rememberedEmail, LoginData.email);
      setCookie(cokeisString.rememberedPassword, LoginData.password);
      setCookie(cokeisString.rememberMe, true);
    } else {
      // If "Remember Me" is not checked, remove saved data from cookies
      removeCookie(cokeisString.rememberedEmail);
      removeCookie(cokeisString.rememberedPassword);
      removeCookie(cokeisString.rememberMe);
    }

    // Continue with your login logic...
  };

  // Function to set a cookie
  const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; path=/`;
  };

  // Function to get the value of a cookie
  const getCookie = (name) => {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=\\s*([^;]+)`
    );
    return cookieValue ? cookieValue.pop() : "";
  };

  // Function to remove a cookie
  const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };



  const phoneUtil = PhoneNumberUtil.getInstance();

  const [phone, setPhone] = useState("");
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    validatePhone();
  }, [phone]);

  const validatePhone = () => {
    try {
      const parsedPhone = phoneUtil.parseAndKeepRawInput(
        phone,
        CurrentCountry?.toLocaleLowerCase()
      ); // Specify the default region code
      const isValidPhone = phoneUtil.isValidNumber(parsedPhone);
      setIsValid(isValidPhone);
    } catch (error) {
      setIsValid(false);
    }
  };
  useEffect(() => {
    if (location.pathname.split("/")[1] === "forgetpassword") {
      setforgetPasswordModal(true);
      setforgetPasswordData((prev) => ({
        ...prev, // Correcting the typo here
        email: location.pathname.split("/")[2], // Correcting the typo here
      }));
      setOTP(location.pathname.split("/")[3]);
    }
    // alert(location.pathname)
  }, [location.pathname]);

  const [showPassword, setShowPassword] = useState(false);
  const [forgetPasswordModal, setforgetPasswordModal] = useState(false);
  const [forgetPasswordData, setforgetPasswordData] = useState({
    password: "",
    repeatPassword: "",
  });
  const [forgetPassworerror, setforgetPassworerror] = useState({
    passwordError: "",
    repeatPasswordError: "",
  });
  const [OTP, setOTP] = useState();

  const [signUpData, setSignUpData] = useState({
    email: "",
    name: "",
    password: "",
    phoneNumber: "",
    repeatPassword: "",
  });

  const [signUpDataError, setSignUpDataError] = useState({
    emailError: "",
    nameError: "",
    passwordError: "",
    phoneNumberError: "",
    repeatPasswordError: "",
  });
  const [loginLoader, setLoginLoader] = useState(false);

  const [connectwalletShow, setconnectwalletShow] = useState(false);

  const [forgotpasswordShow, setforgotpasswordShow] = useState(false);
  // const [SignupShow, setSignupShow] = useState(false);
  const [CreateAcoountWithEmail, setCreateAcoountWithEmail] = useState(false);
  const [saleGraph, setsaleGraph] = useState();
  const [percentage, setPercentage] = useState();
  const [bonusWithoutStage, setBonusWithOutSale] = useState();
  const [firstAttemp, setFirstAttemp] = useState(false);
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [privacySelected, setPrivacySelected] = useState(false);
  const [forgetPasswordloader, setforgetPasswordloader] = useState(false);

  const EmailLoginButtonClick = () => {
    setemailloginShow(true);
    setloginShow(false);
    setSignUpDataError({
      emailError: "",
      nameError: "",
      passwordError: "",
      phoneNumberError: "",
      repeatPasswordError: "",
    })
    setSignUpData({
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      repeatPassword: "",
    });
    setPhone("");
    setLoginData({
      email: "",
      password: "",
    });
    setShowPassword(false);
    setSignupShow(false);
  };
  const ForgetpasswordButtonClick = () => {
    setforgotpasswordShow(true);
    setemailloginShow(false);
    setSignUpDataError({
      emailError: "",
      nameError: "",
      passwordError: "",
      phoneNumberError: "",
      repeatPasswordError: "",
    })
    setSignUpData({
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      repeatPassword: "",
    });
    setPhone("");
    setLoginData({
      email: "",
      password: "",
    });
    setShowPassword(false);
    setloginShow(false);
  };
  const LoginButtonClick = () => {
    setloginShow(true);
    setSignupShow(false);
    setSignUpDataError({
      emailError: "",
      nameError: "",
      passwordError: "",
      phoneNumberError: "",
      repeatPasswordError: "",
    })
    setSignUpData({
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      repeatPassword: "",
    });
    setPhone("");
    setLoginData({
      email: "",
      password: "",
    });
    setShowPassword(false);
    setforgotpasswordShow(false);
  };
  useEffect(() => {
    if (NavBaRSignUp) {
      SignUpButtonClick();
    }
  }, [NavBaRSignUp]);
  const SignUpButtonClick = () => {
    SetPromocode("");
    setSignupShow(true);
    setSignUpDataError({
      emailError: "",
      nameError: "",
      passwordError: "",
      phoneNumberError: "",
      repeatPasswordError: "",
    })
    setSignUpData({
      email: "",
      name: "",
      password: "",
      phoneNumber: "",
      repeatPassword: "",
    });
    setPhone("");
    setLoginData({
      email: "",
      password: "",
    });
    setShowPassword(false);
    setforgotpasswordShow(false);
    setconnectwalletShow(false);
    setemailloginShow(false);
  };
  const ConnectWalletButtonClick = () => {
    setconnectwalletShow(true);
    setloginShow(false);
  };

  useEffect(() => {
    if (CurrentStage?.id)
      getTokenBonusCount(CurrentStage?.id)
        .then((data) => {
          // console.log('getTokenBonusCount',data.total_tokens_purchased)
          setBonusToken(parseInt(data.total_tokens_purchased));
        })
        .catch(() => { });

    getBonusTokenCountWithOutStage()
      .then((data) => {
        setBonusWithOutSale(parseInt(data.total_tokens_purchased));
      })
      .catch(() => { });

    GraphTotal()
      .then((data) => {
        setsaleGraph(data.data.totalraised);
      })
      .catch(() => { });
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("ref");
    SetPromocode(ref);
    if (ref) setSignupShow(true);
    // console.log('Ref:', ref);
  }, [CurrentStage]);

  useEffect(() => {
    let percentage =
      (parseInt(saleGraph) / parseInt(CurrentStage?.total_limit)) * 100;

    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)

    let remaining = parseInt(
      parseInt(CurrentStage?.total_limit) -
      (parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken) >
        parseInt(CurrentStage?.total_limit)
        ? parseInt(CurrentStage?.total_limit)
        : parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken))
    );
    let sold = parseInt(parseInt(TotalSaleInStage) + parseInt(BonusToken));
    setPercentage(parseFloat((sold / (remaining + sold)) * 100).toFixed(2));

    //  setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  }, [TotalSaleInStage, CurrentStage, saleGraph, BonusToken]);

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return passwordRegex.test(password);
  };
  const validateName = (name) => {
    return nameRegex.test(name);
  };


  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    if (isImageLoaded) {
      setBannerModal(true);
    }
  }, [isImageLoaded]);

  useEffect(() => {
    if (firstAttemp) {
      // return phoneNumberObj && phoneNumberObj.isValid();c

      const { email, name, password, phoneNumber, repeatPassword } = signUpData;
      // const phoneNumberObj = parsePhoneNumber(phoneNumber, CurrentCountry);

      const hasSpaceInName = name.includes("");

      let errors = {
        emailError: validateEmail(email) ? "" : "Invalid email format",
        passwordError: validatePassword(password)
          ? ""
          : " Password should be a minimum of 8 digits and include lowercase  uppercase letter and one special character.",
        // phoneNumberError: phoneNumber ? PhoneInput?.isValidPhoneNumber(phoneNumber) ? "" : 'Phone number is not valid ' : "",
        repeatPasswordError:
          password === repeatPassword ? "" : "Passwords do not match",
      };
      // handleOnChange(phoneNumber)
      setSignUpDataError(errors);
    }
  }, [signUpData]);
  const SignUpFunction = (temp) => {
    setLoginLoader(true);
    if (privacySelected)
      Signup(temp)
        .then(() => {
          setLoginLoader(false);
          if (Selectedvalue && SelectedCurrency) navigate("/PurchaseToken");
          else navigate("/Dashboard");
          setSignupShow(false);
        })
        .catch(() => {
          setLoginLoader(false);
        });
    else {
      Notifications("error", "Please check privacy policy");
      setLoginLoader(false)
    }

  };

  const SignUpFunctionWithAddress = (temp) => {
    setLoginLoader(true);
    temp = {
      ...signUpData,
      address: address,
    };
    if (privacySelected)
      SignUpWithEmail(temp)
        .then(() => {
          disconnect();
          setLoginLoader(false);
          if (Selectedvalue && SelectedCurrency) navigate("/PurchaseToken");
          else navigate("/Dashboard");
          setSignupShow(false);
        })
        .catch(() => {
          setLoginLoader(false);
        });
    else {
      Notifications("error", "Please check privacy policy");
      setLoginLoader(false);
    }
  };
  useEffect(() => {
    const { password, repeatPassword } = forgetPasswordData;

    let errors = {
      // passwordError: validatePassword(password)
      //   ? ""
      //   : " Password should be a minimum of 8 digits and include lowercase  uppercase letter and one special character.",
      repeatPasswordError:
        password === repeatPassword ? "" : "Passwords do not match",
    };
    // alert('zaz')
    // console.log(errors )
    setforgetPassworerror(errors);
  }, [forgetPasswordData]);
  // const HandleForgetError = ()=>{

  //   const {  password, repeatPassword } = forgetPasswordData;

  //   let errors = {
  //     passwordError: validatePassword(password) ? "" : " Password should be a minimum of 8 digits and include lowercase  uppercase letter and one special character.",
  //     repeatPasswordError: password === repeatPassword ? "" : "Passwords do not match"
  //   };
  //   setforgetPassworerror(errors)

  // }

  const handleValidation = (check) => {
    // console.log()
    // Toast("Email is alreay taken")
    const { email, name, password, phoneNumber, repeatPassword } = signUpData;

    // console.log("phoneNumber", phoneNumber.split(''))
    // const phoneNumberObj = parsePhoneNumber(phoneNumber, CurrentCountry);

    const hasSpaceInName = name.includes(" ");

    let errors = {
      emailError: validateEmail(email) ? "" : "Invalid email format",

      passwordError: validatePassword(password)
        ? ""
        : " Password should be a minimum of 8 digits and include lowercase  uppercase letter and one special character.",
      // phoneNumberError: phoneNumber ? PhoneInput?.isValidPhoneNumber(phoneNumber) ? "" : 'Phone number is not valid ' : "",
      repeatPasswordError:
        password === repeatPassword ? "" : "Passwords do not match",
    };
    // handleOnChange(phoneNumber)
    // here if no error found and all fields are  ok alert ok
    setFirstAttemp(true);

    const noErrors = Object.values(errors).every((error) => error === "");

    // If no errors, show an "OK" alert
    let temp;
    if (Promocode) {
      temp = {
        ...signUpData,
        referral_link: Promocode,
      };
    } else {
      temp = signUpData;
    }

    if (noErrors) {
      if (SelectedCurrency) {
        if (Selectedvalue) {
          if (!check) {
            SignUpFunction(temp);
          } else {
            SignUpFunctionWithAddress(temp);
          }
        } else {
          Notifications("error", "Please enter amount");
        }
      } else {
        if (!check) {
          SignUpFunction(temp);
        } else {
          SignUpFunctionWithAddress(temp);
        }
      }
    }
    else {
      setLoginLoader(false)
    }
    // If no errors, show an "OK" alert

    setSignUpDataError(errors);
  };

  const LoginFun = () => {

    if (!LoginData.email) {

      Notifications("error", "Email is Required");
      return;
    }
    if (!LoginData.password) {

      Notifications("error", "Password is Required");
      return;
    }
    setLoginLoader(true);
    handleLogin();
    Login(LoginData)
      .then((data) => {
        setLoginLoader(false);
        // console.log(data,'LoginData' )
        SetUserDetail(data);
        setUserId(data?.Data?.id);
        if (data?.Data?.twofactor) {
          setTemptToken(data?.token);
          setPasswordModal(true);
          setOtpLoader(true)
          setPasswordModal(true)
          Notifications("success", " OTP has sent to your Registered Email");
          setOtpLoader(false)
        } else {

          if (data?.token) {
            localStorage.setItem("JwtToken", data?.token)
            navigate("/Dashboard");
            Notifications("success", "User Logged In Successfully");
          }
          else {
            Notifications("error", 'Account Suspended');
          }



        }
      })
      .catch(() => {
        setLoginLoader(false);
      });
  };

  const Check_OTP_OnLogin = () => {
    setFactorLoader(true);
    const data = {
      user_id: UserId,
      otp: PasswordOTP,
    };
    ValidateOTPonLogin(data)
      .then(() => {
        setFactorLoader(false);
        localStorage.setItem("JwtToken", tempToken);

        navigate("/Dashboard");
        Notifications("success", "User Logged In Successfully");
      })
      .catch(() => {
        setFactorLoader(false);
        Notifications("error", "OTP Login Failed");
      });
  };
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: "btc",
    value: "btc",
  });
  const [currencies, setCurrencies] = useState([]);

  // const options = currencies.map((item) => ({
  //   label: item.currency,
  //   value: item.currency,
  // }));
  const options = CurrenicesArray.map((item) => ({
    label: item.label == usdterc20.name ? usdterc20.label :
      item.label == usdtbsc.name ? usdtbsc.label :
        item.label == usdttrc20.name ? usdttrc20.label :
          item.label.toUpperCase(),
    image: item.image,
    value: item.label,
  }));
  useEffect(() => {
    GetAllAvaliableCurrencies()
      .then((currencies) => {
        // console.log(currencies)
        setCurrencies(currencies.currencies);
      })
      .catch(() => { });
  }, []);
  const customStyles = {
    control: (provided) => ({
      ...provided,

      borderRadius: "20px",
      backgroundColor: "transparent",
      // height:'42px',
      color: "white",
      // innerHeight:'42px',
      outerHeight: "42px",
      border: "1px solid #ccc",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const HandleForgetPassword = () => {
    setforgetPasswordloader(true);
    const noErrors = Object.values(forgetPassworerror).every(
      (error) => error === ""
    );
    if (noErrors) {
      const data = {
        email: forgetPasswordData?.email,
        newpassword: forgetPasswordData?.password,
        otp: OTP,
      };
      if (!forgetPasswordloader) {
        setforgetPasswordloader(true);

        ResetProfilePassword(data)
          .then(() => {
            setforgetPasswordloader(false);
            setforgetPasswordModal(false);

            Notifications("success", "Password updated Successfully");
          })
          .catch(() => {
            setforgetPasswordloader(false);
          });
      }
    }
  };

  useEffect(() => {
    if (address) {
      setloginShow(false);
    }
  }, [address]);

  const ResetPasswordFunction = () => {
    if (!forgetPasswordloader) {
      setforgetPasswrodLoader(true);
      forgetPasswordApi({ email: EmailReset })
        .then((x) => {
          setforgetPasswrodLoader(false);
          Notifications("success", "Email sent Successfully");
        })
        .catch(() => {
          setforgetPasswrodLoader(false);
          Notifications("error", "No Account Found");
        });
    }
  };
  useEffect(() => {
    try {
      const cookie = document?.cookie
        ?.split(";")
        ?.find((cookie) => cookie?.includes("googtrans"));

      if (cookie) {
        const languageFromCookie = cookie.split("/")[2];

        if (languageFromCookie) {
          setSelectedLanguage(languageFromCookie);
        }
      }
    } catch (error) {
      // console.error("Error while processing cookie:", error);
    }
  }, []);

  function removeCookie2(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  const removeCookie3 = (name, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain};`;
  };

  function setCookie2(key, value, expiry) {
    // console.log("mazak ha key", key)
    removeCookie2(key);
    return new Promise((resolve) => {
      setTimeout(() => {
        var existingCookie = document.cookie;
        var cookies = existingCookie?.split(";")[0];
        // console.log("mazak ha existing existingCookie?split[0]", existingCookie?.split(";")[0])
        // console.log("mazak ha existing Cookie", existingCookie)
        // console.log("mazak ha existing split ", cookies)

        // Check if the key already exists in the cookies
        var updatedCookie = false;
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(key + "=") === 0) {
            updatedCookie = true;
            // Update the existing cookie value
            var cookieValue = cookie.substring(key.length + 1);
            document.cookie =
              key + "=" + value + "; expires=" + expiry + "; path=/";
            break;
          }
        }

        // console.log("mazak ha updated updatedCookie", updatedCookie)

        if (!updatedCookie) {
          // Create a new cookie if the key doesn't exist
          document.cookie =
            key + "=" + value + "; expires=" + expiry + "; path=/";
        }
        resolve();
      }, 1000);
    });
  }
  function setCookie3(key, value, expiry, domain) {
    // console.log(key, value, expiry,domain )
    removeCookie3(key, domain);
    return new Promise((resolve) => {
      setTimeout(() => {
        var existingCookie = document.cookie;
        var cookies = existingCookie.split(";");

        var updatedCookie = false;

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(key + "=") === 0) {
            updatedCookie = true;
            var cookieValue = cookie.substring(key.length + 1);
            document.cookie =
              key +
              "=" +
              value +
              "; expires=" +
              expiry +
              "; path=/; domain=" +
              domain;
            break;
          }
        }

        if (!updatedCookie) {
          document.cookie =
            key +
            "=" +
            value +
            "; expires=" +
            expiry +
            "; path=/; domain=" +
            domain;
        }

        resolve();
      }, 1000);
    });
  }

  const googleTranslateElementInit = () => {
    const element = new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,ar,zh-CN,fr,de,id,it,ko,pt,es,th,vi",
        layout:
          window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    // console.log(document.documentElement.lang,"language")

    const interval = setInterval(() => {
      if (
        document.documentElement.lang === localStorage.getItem("Language") ||
        (document.documentElement.lang === "en" &&
          localStorage.getItem("Language") === "lo")
      ) {
        clearInterval(interval);
      }
    }, [1000]);
  }, []);

  // useEffect(() => {
  //   // console.log(" googleTranslateElementInit true")
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute("src", Google_Script_Url);
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;

  //   // console.log(" googleTranslateElementInit false")
  // }, [language]);

  useEffect(() => {
    setctaModal(false)
    setloginShow(false);
    console.log(events, 'events')
  }, [events]);
  useEffect(() => {
    if (address)
      getDetailofUserwithWallet(address)
        .then((data) => {
          setuserDeatilofaddress(data);
          // console.log(data, 'setuserDeatilofaddress')
          // console.log(data, 'setuserDeatilofaddress');
        })
        .catch(() => {
          // Handle error if needed
        });
  }, [isDisconnected, isConnecting, address]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isDisconnected && !isConnecting && address && userDeatilofaddress) {
        const { email } = userDeatilofaddress;
        if (email && !email.includes("@")) {
          setCreateAcoountWithEmail(true);
        }
      }
    }, 60000); // 1 minute interval in milliseconds

    return () => {
      // Cleanup function to clear the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, [isDisconnected, isConnecting, address, userDeatilofaddress]);
  useEffect(() => {
    // Get all input fields
    const inputFields = document.querySelectorAll("input");

    // Loop through each input field and set autocomplete to 'off'
    inputFields.forEach((inputField) => {
      inputField.setAttribute("autocomplete", "off");
    });
  }, []);


  useEffect(() => {
    setSignUpDataError({
      emailError: "",
      nameError: "",
      passwordError: "",
      phoneNumberError: "",
      repeatPasswordError: "",
    })
    setIsValid(true)
  }, [SignupShow])



     useEffect(()=>{
      GetTokenRate().then((item)=>{
            
        SetPriceOfToken(item / web3Value)
          
        // console.log(item / web3Value ,'GetTokenRate')

      }).catch((item)=>{
        // console.log(item,'GetTokenRate error')
      })


      Get_latestPromoCode().then((promocode)=>{
        Check_PromoCode(promocode[0]).then((percentage) => {
          SetPromotioncode(
            {
              name:promocode[0],
              bonus:percentage

            }
          )
         }).catch(()=>{

          })
              
  


      }).catch(()=>{

      })
     },[])
 

     
  return (
    <>


      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
   
         
          <Navbar.Brand href="/">
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>

          <div style={{ display: "flex" }}>
            {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
            <div
              id="google_translate_element"
              style={{ display: "none" }}
            />
          </div>
          <Navbar.Collapse id="navbarScroll">
            <>
              <Nav className="me-auto my-2 my-lg-0">
                <Nav.Link
                  href={void 0}
                  onClick={() => scrollToFeatured()}
                // className={`${
                //   useLocation().pathname == "/About" ? "active" : ""
                // }`}
                >
                  About
                </Nav.Link>
                {/* <Nav.Link
                  href={void 0}
                  onClick={() => scrollToFeatured()}
                  // className={`${
                  //   useLocation().pathname == "/Features" ? "active" : ""
                  // }`}
                >
                  Features
                </Nav.Link> */}
                {/* <Nav.Link
                  href={void 0}
                  onClick={() => RewardToFeatured()}
                  // className={`${
                  //   useLocation().pathname == "/Rewards" ? "active" : ""
                  // }`}
                >
                  Rewards
                </Nav.Link> */}
                <Nav.Link
                  href={WhitePaperLink}


                  // className={`${
                  //   useLocation().pathname == "/Faq" ? "active" : ""
                  // }`}
                  target="_blank"
                >
                  White paper
                </Nav.Link>
                <Nav.Link
                  href={void 0}
                  onClick={() => TokenToFeatured()}
                // className={`${
                //   useLocation().pathname == "/Tokemics" ? "active" : ""
                // }`}
                >
                  tokenomics
                </Nav.Link>
                <Nav.Link
                  href={void 0}
                  onClick={() => RoadToFeatured()}
                // className={`${
                //   useLocation().pathname == "/Roadmap" ? "active" : ""
                // }`}
                >
                  key projects
                </Nav.Link>
                <Nav.Link
                 href={void 0}
               onClick={() => FaqToFeatured()}
                // className={`${
                //   useLocation().pathname == "/Faq" ? "active" : ""
                // }`}
                >
                  FAQ
                </Nav.Link>



                <div className="mobile-view">
                  <Link
                    style={{ alignSelf: 'center', whiteSpace: 'nowrap' }}
                    to="javascript:void(0);"
                    className="reg-btn-connect br-radius"
                  >
                         <a
                           href="/Dashboard"
                        className="reg-btn br-radous gradient mmt-3"
                     >
                        Join PreSale
                      </a>

                  </Link>
            
                </div>
              </Nav>
            </>
          </Navbar.Collapse>
   

          <a   
          id='PreSale-btn'
                           href="/Dashboard"
                        className="reg-btn br-radous gradient mmt-3"
                     >
                        Join PreSale
                      </a>
        </Container>
 
      </Navbar>
       <div className="bottom-line">
            {Promotioncode?.name && (
              <p>
                Use promo <span>"{Promotioncode?.name}"</span> to get{" "}
                {Promotioncode?.bonus}% <span>bonus!</span>
              </p>
            )}
          </div>
  
      {/* Login Modal Starts Here */}
      {/* <Modal
        size="md"
        show={loginShow}
        centered
        onHide={() => setloginShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="log-panel">
          <Button className="close-btn" onClick={() => setloginShow(false)}>
            <i className="fa fa-close" />
          </Button>
          <h3>JOIN PRESALE</h3>
          <p>
          To log in, please click 'JOIN PRESALE' to buy a token and proceed
          </p>

          <Button
            className="reg-btn br-radius trans"
            onClick={() => { navigate('/Dashboard') }}
          >
            JOIN PRESALE
          </Button>
          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2024 All Rights Reserved</span>
        </Modal.Body>
      </Modal> */}

      {/* <Modal
        size="md"
        show={ctaModal}
        centered
        onHide={() => setctaModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="log-panel">
          <Button className="close-btn" onClick={() => setctaModal(false)}>
            <i className="fa fa-close" />
          </Button>
          <h3>JOIN PRESALE</h3>
          <p>
          To log in, please click 'JOIN PRESALE' to buy a token and proceed
          </p>
          <Button
            className="reg-btn br-radius trans"
            onClick={() => { navigate('/Dashboard') }}
          >
             JOIN PRESALE
          </Button>
          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2024 All Rights Reserved</span>
        </Modal.Body>
      </Modal> */}

      {/* Login Modal Ends Here */}

      {/* Connect Wallet Modal Starts Here */}
      {/* <Modal
        size="md"
        show={connectwalletShow}
        centered
        onHide={() => setconnectwalletShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="log-panel">
          <Button
            className="close-btn"
            onClick={() => setconnectwalletShow(false)}
          >
            <i className="fa fa-close" />
          </Button>
          <h3>Login</h3>

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                autoComplete="off"
                className="input-email"
                placeholder="Enter Your Email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                autocomplete="off"
                className="input-name"
                placeholder="Enter Your name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="number"
                autoComplete="off"
                placeholder="Enter your connect number"
              />
            </Form.Group>
            <div className="text-right">
              <Button
                to="/"
                className="simple-link purple"
                onClick={() => setconnectwalletShow(false)}
              >
                Skip
              </Button>
            </div>
          </Form>
          <div className="spacer-40" />
          <Button className="reg-btn br-radius" to="/">
            Login
          </Button>
          <div className="spacer-40" />
          <p>
            Still don't have an account?{" "}
            <Button
              className="simple-link purple"
              onClick={() => SignUpButtonClick()}
            >
              Sign-Up here
            </Button>
          </p>
          <ul className="btn-list justify-center">
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/TermsConditions"
              >
                Terms & conditions
              </Link>
            </li>
            <li>
              <Link
                target="_blank" // This opens the link in a new tab/window
                rel="noopener noreferrer" // Recommended for security reasons
                to="/PrivacyPolicy"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <span>© 2024 All Rights Reserved</span>
        </Modal.Body>
      </Modal> */}
      {/* Connect Wallet Modal Ends Here */}

      {/* Login WIth Email Modal Starts Here */}

      {/* Login WIth Email Modal Ends Here */}

      {/* Login WIth Email Modal Starts Here */}
 

      
      {/* Login WIth Email Modal Ends Here */}

      {/* Sign Up Modal Starts Here */}

    

      <>
      <img
        src={popBanner}
        onLoad={handleImageLoad}
        style={{ display: 'none' }} // Hide the image element
        alt="Banner"
      />
      {/* <Modal
        size="md"
        show={bannerModal}
        centered
        onHide={() => setBannerModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body style={{ backgroundColor: '#202441' }}>
          <Button className="close-btn" onClick={() => setBannerModal(false)}>
            <i className="fa fa-close" />
          </Button>
          <img src={popBanner} alt="Banner" />
        </Modal.Body>
      </Modal> */}
    </>
      {/* Sign Up Modal Ends Here */}
    </>
  );
}
export default NavBar;
