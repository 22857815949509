import axios from 'axios';
import Web3 from "web3";
import {  Base_Url, NowPaymentBaseUrl , Base_Url_V1, ContractAddressICO, web3_custom, Get_TokenBalabce_abi, USDTAddress, NotBonkAddress, Get_TokenApprove_Abi } from '../Constants';
import { Notifications } from '../Components/Toast';
import { TokenAbi } from './Tokenabi';
import { writeContract } from '@wagmi/core'
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import { useWalletClient } from 'wagmi'; // Adjust this import according to your setup
import { USDTABI } from './USDTabi';


export const Signup = async (body) => {
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('accounts/Signup',body, {
        baseURL: Base_Url,
      });
      localStorage.setItem("JwtToken",response.data.token )
   
      resolve(response.data);
    } catch (error) {
      // console.log( error)

        Notifications('error',error.response.data.message)
      reject(error);
    }
  });
};


export const Login = async (body) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('accounts/Login',body, {
        baseURL: Base_Url,
      });
      if(response?.data?.message==='User is block')
      {
        Notifications('error',"Your account is blocked ")
        reject('error');
      }
      // console.log(response.data.token)
      resolve(response.data);

    } catch (error) {
        Notifications('error',"Email or Password is incorrect")
      reject(error);
    }
  });
};

export const UpdateProfileApi = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post('accounts/update',body, {
      baseURL: Base_Url,
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
    });

    // console.log(response.data.token)
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const UpdateProfilePassword = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post('accounts/updatePassword',body, {
      baseURL: Base_Url,
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
    });

    // console.log(response.data.token)
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const ResetProfilePassword = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post('accounts/forgetPasswordRequest',body, {
      baseURL: Base_Url,
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
    });

    // console.log(response.data.token)
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const forgetPasswordApi = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post('accounts/forgetPassword',body, {
      baseURL: Base_Url,
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
    });

    // console.log(response.data.token)
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};


export const getUserDetail = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get('accounts/getDetailofUser', {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      if(error?.response?.data?.message)
            Notifications('error',"Your Account Is Blocked")


    reject(error);
  }
});
};
export const getDetailofUserwithWallet = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getDetailofUserwithWallet?address=${body}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {

      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};


export const getUserActivity = async (id,page) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getUserActivity?userId=${id}&CurrentPage=${page}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const deleteActivities = async (id,page) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/deleteActivities?userId=${id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const Enable2FA_Api = async (id,page) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/enable2FA?userId=${id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const CheckFactorVerification = async (data) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(`accounts/CheckFactorVerification`,data, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};






export const getTotalTokenOfUser = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

// Bonus = false , Refral = false , purchase = false
export const getPurchaseTokenOfUser = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}&purchase=true`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const getReferralTokenOfUser = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}&Refral=true`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const getBonusTokenOfUser = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}&Bonus=true`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};



export const GetCurrentStage = async (body) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('stages/getActiveStage', {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};

export const GetPromoCode = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get('stages/getPromotion', {
      baseURL: Base_Url,
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};

export const GetCurrentStageTransactions = async (stageId) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`transactions/getCountOfTransactionOfStage?stageId=${stageId}`, {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};

export const GetAllStageTransactions = async (stageId) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`transactions/getCountOfTransactionOfStage`, {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};




export const MakeTransaction = async (body) => {
  try {
    const response = await axios.post(
      `${Base_Url}/transactions/CreateTransaction`, // Assuming Base_Url is defined somewhere
      body, // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};
export const MakeTransactionWithAddress = async (body) => {
  try {
    const response = await axios.post(
      `${Base_Url}/transactions/CreateTransactionWithAddress`, // Assuming Base_Url is defined somewhere
      body, // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};



export const UpdateTransaction = async (body) => {
  try {
    const response = await axios.post(
      `${Base_Url}/transactions/UpdateTransaction`, // Assuming Base_Url is defined somewhere
      body, // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};


export const CreatePayment = async (body) => {

  const obj = 
  {
    "price_amount": body.amount,
    "price_currency": "usd",
    "pay_currency": body.selectedCurrency==='bnb'?'bnbbsc':body.selectedCurrency,
    "ipn_callback_url": "https://be.notbonk.xyz/callback",
    "order_id": body.transaction_number,
    "order_description": body?.description?? "NBONK Token Purchase",
    "is_fixed_rate": false,
    "is_fee_paid_by_user": false
  }
  try {
    const response = await axios.post(`${Base_Url}admin/CreatePayment`, obj);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// export const CreatePayment = async (body) => {
//   return new Promise(async (resolve, reject) => {
//   try {

//     const obj = 
//       {
//         "price_amount": body.amount,
//         "price_currency": "usd",
//         "pay_currency": body.selectedCurrency==='bnb'?'bnbbsc':body.selectedCurrency,
//         "ipn_callback_url": "https://be.notbonk.xyz/callback",
//         "order_id": body.transaction_number,
//         "order_description": body?.description?? "NBONK Token Purchase",
//         "is_fixed_rate": false,
//         "is_fee_paid_by_user": false
//       }
//     const response = await axios.post(`${NowPaymentBaseUrl}v1/payment`,obj, {
//       headers :{
//         'x-api-key' : Api_key,
//         'Content-Type' : 'application/json'
//       }
      
//     });
//     resolve(response.data);
//   } catch (error) {
//       //  Notifications('error',"Email is Already Taken")
//     reject(error);
//   }
// })
// };


export const getAllTransactions = async (userId,searchLikeTx,CurrentPage,PageSize,sorting) => {
  try {
    const response = await axios.get(
      `${Base_Url}/transactions/getAllTransactionOfUser?CurrentPage=${CurrentPage}&PageSize=${PageSize}&userId=${userId}&searchLikeTx=${searchLikeTx??''}&sorting=${sorting}`, // Assuming Base_Url is defined somewhere
        // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};

export const searchTransaction = async (transactionhash,userId) => {
  try {
    const response = await axios.get(
      `${Base_Url}/transactions/getAllTransactionOfUser?userId=${userId}&searchTx=${transactionhash}`, // Assuming Base_Url is defined somewhere
        // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};


export const GetAllAvaliableCurrencies = async () => {
  try {
    const response = await axios.post(`${Base_Url}admin/GetAllAvailableCurrencies`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const GetAllAvaliableCurrenciesWithLogo = async () => {
  try {
    const response = await axios.post(`${Base_Url}admin/GetAllAvailableCurrenciesWithLogo`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};







export const GetSelectedCurrencyPrice = async (currency) => {
  try {
    const response = await axios.post(`${Base_Url}admin/GetSelectedCurrencyPrice`, { currency });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};




export const getUserReferralHistory = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/referralhistory?user_Id=${id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};


export const CheckPromoValidity = async (promo,accountId) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`transactions/CheckPromoCode?promo=${promo}&accountId=${accountId}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const getSumOfCurrencies = async (promo,accountId) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`transactions/totalRaised`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};




export const getTotalTokenOfwallet = async (address) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfwallet?address=${address}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const WalletaddressChanage = async (user_id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/WalletaddressChanage?user_id=${user_id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const WalletaddressRequest = async (data) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(`accounts/WalletaddressRequest`,data, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
    Notifications('error',error.response.data.message)
    reject(error);
  }
});
};


export const SignUpWithEmail = async (data) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(`accounts/signUpWithEmail`,data, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    localStorage.setItem("JwtToken",response.data.token )
   
      resolve(response.data);

  } catch (error) {
    Notifications('error',error.response.data.message)
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};


export const getTokenBonusCount = async (stageId) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`admin/getBonusTokenCount?stageId=${stageId}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
      resolve(response.data);

  } catch (error) {
    Notifications('error',error.response.data.message)
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const getBonusTokenCountWithOutStage = async () => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`admin/getBonusTokenCountwithOutStage`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
      resolve(response.data);

  } catch (error) {
    Notifications('error',error.response.data.message)
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};



export const getSaleInStage = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`stages/getSaleInStage?StageID=${body}`, {
      baseURL: Base_Url,
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};

// GraphTotal
export const GraphTotal = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`transactions/GraphTotal`, {
      baseURL: Base_Url,
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
}; 


export const ValidateOTPonLogin = async (data) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(`accounts/handleOTPLogin`,data, {
      baseURL: Base_Url,
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
}; 



export const GetTokenRate = () => {
  const contract = new web3_custom.eth.Contract(
    TokenAbi,
    ContractAddressICO
  );
  return new Promise((resolve, reject) => {
    contract.methods
      .getRate()
      .call()
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetMinimumPurchase = () => {
  const contract = new web3_custom.eth.Contract(
    TokenAbi,
    ContractAddressICO
  );
  return new Promise((resolve, reject) => {
    contract.methods
      .getMinimumBuy()
      .call()
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};



export const GetBalanceOfUsdt = (address) => {
  const contract = new web3_custom.eth.Contract(
    Get_TokenBalabce_abi,
    USDTAddress
  );
  return new Promise((resolve, reject) => {
    contract.methods
      .balanceOf(address)
      .call()
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetBalanceOfNBonk = (address) => {
  const contract = new web3_custom.eth.Contract(
    Get_TokenBalabce_abi,
    NotBonkAddress
  );
  return new Promise((resolve, reject) => {
    contract.methods
      .balanceOf(address)
      .call()
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const ApproveUsdt = (amount) => {
  const contract = new web3_custom.eth.Contract(
    Get_TokenApprove_Abi,
    USDTAddress
  );
  return new Promise((resolve, reject) => {
    contract.methods
      .approve(amount)
      .call()
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const Token_approvel = async (tokenaddress, approveAddress, amount) => {




  try {
  const data =   await writeContract({
      address: tokenaddress,
      abi: USDTABI,
      functionName: "approve",
      args: [approveAddress, amount],
    });
  
    return true
  } catch (error) {
    console.error("Approval error:", error);
  }



    
  // console.log('Requesting wallet client...');
  // const { data: walletClient, isError, isLoading } = useWalletClient();

  // if (isLoading) {
  //   console.log('Loading wallet client...');
  //   return;
  // }

  // if (isError || !walletClient) {
  //   console.error('Error getting wallet client:', isError);
  //   throw new Error('Error getting wallet client');
  // }

  // const signer = walletClient.getSigner();

  //  alert('i')
  // try {
  //   console.log('Creating contract instance...');
  //   const contract = new ethers.Contract(tokenaddress, minABI, signer);

  //   console.log('Sending approval transaction...');
  //   const tx = await contract.approve(approveAddress, amount, {
  //     // gasLimit: 300000, // Uncomment if needed
  //   });

  //   console.log('Waiting for transaction confirmation...');
  //   const receipt = await tx.wait();
  //   console.log('Transaction confirmed:', receipt);
  //   return receipt;
  // } catch (error) {
  //   console.error('Error executing approval', error);
  //   throw error;
  // }



};
export const BuyTokenFunCall = async (amount) => {

  try {
    await writeContract({
      address: ContractAddressICO,
      abi: TokenAbi,
      functionName: "buyToken",
      args: [amount * (10 ** 18)],
    });
    return true;
  } catch (error) {
    console.error("Approval error:", error);
  }

};



export const BuyTokenFunCallWithPromo = async (amount, text) => {


  try {
    await writeContract({
      address: ContractAddressICO,
      abi: TokenAbi,
      functionName: "buyWithPromo",
      args: [amount * (10 ** 18), text],
    });
    return true;
  } catch (error) {
    console.error("Approval error:", error);
  }
};


export const Check_PromoCode = async (text) => {

  const contract = new web3_custom.eth.Contract(
    TokenAbi,
    ContractAddressICO
  );
  return new Promise((resolve, reject) => {
    contract.methods
      .getpromoDetails(text)
      .call()
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });

  
};

export const Get_latestPromoCode = async (text) => {

  const contract = new web3_custom.eth.Contract(
    TokenAbi,
    ContractAddressICO
  );
  return new Promise((resolve, reject) => {
    contract.methods
      .getPromoList(0 ,10)
      .call()
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });

  
};





export const connectWithMetamask = async () => {
  try {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);

      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      
      const TempchainId = parseInt(await web3.eth.net.getId());
      const balance = await web3.eth.getBalance(accounts[0]);

   

   

      return { TempchainId, account: accounts[0], balance };
    } else {
      throw new Error("Please install the Metamask extension");
    }
  } catch (error) {
    console.error("Error connecting with Metamask:", error);
    throw error;
  }
};



export const switchingToRLC = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0xa33' }],
    });

  } catch (switchError) {

    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0xa33',
              chainName: 'RLC Main-net',
              rpcUrls: ['https://dataseed2.redlightscan.finance/'],
              blockExplorerUrls: ['https://redlightscan.finance/'],
              nativeCurrency: {
                symbol: 'RLC',
                decimals: 18
              }
            }
          ],
        })

      } catch (addError) {
      }
    }

  }
}


export const AddNotBonkToken = async () => {
  try {
    if (window.ethereum) {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: NotBonkAddress, 
            symbol: "NBONK",
            decimals: 18, 
          },
        },
      });
    } else {
      Notifications('error' , "MetaMask not detected")

    }
  } catch (error) {
  }
};

export const AddUSDTToken = async () => {

    const { data: walletClient } = useWalletClient();

      try {
        await walletClient.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: USDTAddress,
              symbol: 'USDT',
              decimals: 6,
            },
          },
        });
      } catch (error) {
        console.error('Failed to add token:', error);
      }
    
  
};