
import {LatestBlock ,
  PriceOftkoen,
  TotalSaleInStage,CurrentStage, ConnectedAddress,OverAllSaleToken ,Promocode, UserDetail, SelectedCurrency, Selectedvalue, Promotioncode, showTransaction, NavBaRSignUp} from "./type"
export default (state, action) => {
  switch (action.type) {

      case LatestBlock:
      return {
        ...state,
        LatestBlock: action.data,
      }
      case CurrentStage:
        return {
          ...state,
          CurrentStage: action.data,
        }
        case TotalSaleInStage:
          return {
            ...state,
            TotalSaleInStage: action.data,
          }
          case OverAllSaleToken:
            return{
              ...state,
              OverAllSaleToken:action.data
            }
            case Promocode:
              return{
                ...state,
                Promocode:action.data
              }
              case UserDetail:
                return{
                  ...state,
                  UserDetail:action.data
                }
                case SelectedCurrency:
                  return{
                    ...state,
                    SelectedCurrency:action.data
                  }
                  case Selectedvalue:
                    return{
                      ...state,
                      Selectedvalue:action.data
                    }
                    case Promotioncode:
                      return{
                        ...state,
                        Promotioncode:action.data
                      }
                      case showTransaction:
                        return{
                          ...state,
                          showTransaction:action.data
                        }
                        case NavBaRSignUp:
                          return{
                            ...state,
                            NavBaRSignUp:action.data
                          }
                          case PriceOftkoen:
                            return{
                              ...state,
                              PriceOftkoen:action.data
                            }
                            case ConnectedAddress:
                              return {
                                ...state,
                                ConnectedAddress: action.data
                              }
                        

     
    default:
      return state
  }
}
