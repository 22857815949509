import React from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import whoweare from "../Assets/images/who-we-are.png";
import videocover from "../Assets/img/video-cover.png";
import playicon from "../Assets/img/Icons/icon-video.png";
import { PresaleButton, PresalesButton } from "./Buttons";
import { useAccount, useDisconnect } from "wagmi";

import { videoUrl } from "../Constants";
function Whoweare({ setSignupShow }) {
  const { address, isConnecting, isDisconnected } = useAccount();
  return (
    <>
      <Row>
        <Container className="zindex">
          <Row
            onClick={() => {
              setSignupShow(true);
            }}
          >
            <Col xl="4" lg="4" md="12" sm="12">
              <img src={whoweare} alt="Who We Are" />
            </Col>
            <Col xl="8" lg="8" md="12" sm="12">
              <div className="spacer-40" />
            
              <h2 className="text-uppercase font-weight-normal">
              About <span>Notbonk</span>
                </h2>
              <p>
                Introducing NOTBONK token, the ultimate twist in the world of
                meme coins! Born on the Binance Smart Chain, NOTBONK is the rebel
                without a cause, the prankster of the crypto world. No
                complicated protocols, no token utility across a wide base of
                applications. We're the Meme token that doesn't take itself too
                seriously, and that's our charm.
              </p>
              <p>
                Why stick with the ordinary when you can join the extraordinary
                journey of NOTBONK?
              </p>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  );
}
export default Whoweare;
