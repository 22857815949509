import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Container, Col, Form, Modal, Button } from "react-bootstrap";

import icontether from "../Assets/img/Icons/icon-teather.png";
import icontether1 from "../Assets/img/Icons/icon-teather-1.png";
import sold from "../Assets/img/sold.png";
import iconcheck from "../Assets/img/Icons/icon-check.png";
import iconcross from "../Assets/img/Icons/icon-cross.png";
import coincard from "../Assets/img/Icons/icon-card.png";
import coinusdc from "../Assets/img/Icons/icon-usdc.png";
import coinbusd from "../Assets/img/Icons/icon-busd.png";
import coinbnb from "../Assets/img/Icons/icon-bnb.png";
import coincrypto from "../Assets/img/Icons/icon-crypto.png";
import coineth from "../Assets/img/Icons/icon-eth.png";
import coinusdt from "../Assets/img/Icons/icon-usdt.png";
import post1 from "../Assets/images/post/post-bg-1.png";
import post2 from "../Assets/images/post/post-bg-2.png";
import post3 from "../Assets/images/post/post-bg-3.png";

import Consistency from "../Assets/img/Icons/icon-consistancy.png";
import data from "../Assets/img/Icons/icon-data-driven.png";
import Accessibility from "../Assets/img/Icons/icon-accesability.png";

import Batch from "../Assets/images/icons/batch.svg";
import Coin from "../Assets/images/icons/coin.svg";
import Award from "../Assets/images/icons/award.svg";
import Balls from "../Assets/images/icons/balls.svg";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UserContest from "../ContextAPI/UserContest";
import {
  GetBalanceOfNBonk,
  GetBalanceOfUsdt,
  GraphTotal,
  getBonusTokenCountWithOutStage,
  getSumOfCurrencies,
  getTokenBonusCount,
  getTotalTokenOfwallet,
  getUserDetail,
} from "../Api";
import PurchaseTokenWithWallet from "../Pages/AdminPanel/PurchaseTokenWithWallet";
import bgimg from "../Assets/images/bg.png";
import icon1 from "../Assets/img/Icons/svg3409.png";
import icon2 from "../Assets/img/Icons/coins (1).png";
import icon3 from "../Assets/img/Icons/coins (2).png";
import icon4 from "../Assets/img/Icons/coins.png";
import icon5 from "../Assets/img/Icons/Frame.png";
import icon6 from "../Assets/img/Icons/icon.png";
import icon7 from "../Assets/img/Icons/Layer_1 (1).png";
import icon8 from "../Assets/img/Icons/Layer_1.png";

import { useAccount, useDisconnect } from "wagmi";

import {
  BannerVideo,
  CommasRegex,
  ContractAddressICO,
  DiscordIcon,
  InstagramUrl,
  TotalToken,
  facebookIcon,
  linkedinIcon,
  teleGramUrl,
  twitterIcon,
  web3Value,
} from "../Constants";

import twitter from "../Assets/img/Icons/Twitter.png";
import Discord from "../Assets/img/Icons/Discord.png";
import gitt from "../Assets/img/Icons/icon-git.png";
import telegram from "../Assets/img/Icons/Telegram.png";
import facebook from "../Assets/img/Icons/Facebook.png";
import instagram from "../Assets/img/Icons/Instagram.png";

import linkedin from "../Assets/img/Icons/Linkindin.png";

// import { Promocode } from "../ContextAPI/type";
function Header({ setSignupShow, SignupShow }) {
  const {
    CurrentStage,
    TotalSaleInStage,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
    SetUserDetail,
    PriceOftkoen,
  } = useContext(UserContest);
  const iconsArray = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8];

  const [SelectedChain, setSelectedChain] = useState();
  const [BonusToken, setBonusToken] = useState();
  const navigate = useNavigate();
  const [totalToken, settotalToken] = useState(0);
  const [BalanceOfNbonk, setBalanceOfNbonk] = useState();
  const [saleGraph, setsaleGraph] = useState();
  const [bonusWithoutStage, setBonusWithOutSale] = useState();
  // const address =?
  const { address, isConnecting, isDisconnected } = useAccount();
  const [Totalraised, setTotalraised] = useState(0);
  const { disconnect } = useDisconnect();
  const [percentage, setPercentage] = useState();
  const Network = {
    mainNetwork: "mainNetwork",
    BUSD: "BUSD",
    USDT: "USDT",
    USDC: "USDC",
  };
  useEffect(() => {
    GraphTotal()
      .then((data) => {
        // console.log('GraphTotal',data.data.totalraised )
        setsaleGraph(data.data.totalraised);
      })
      .catch(() => {});
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (CurrentStage?.id)
      getTokenBonusCount(CurrentStage?.id)
        .then((data) => {
          // console.log('getTokenBonusCount',data.total_tokens_purchased)
          setBonusToken(parseInt(data.total_tokens_purchased));
        })
        .catch(() => {});
    getBonusTokenCountWithOutStage()
      .then((data) => {
        setBonusWithOutSale(parseInt(data.total_tokens_purchased));
      })
      .catch(() => {});
    getUserDetail()
      .then((detail) => {
        // console.log(detail ,'getUserDetail' )
        // navigate("/Dashboard");
        SetUserDetail(detail);
      })
      .catch(() => {
        if (localStorage.getItem("JwtToken")) {
          localStorage.clear();
        }
        // navigate('/')
      });

    let percentage =
      (parseInt(saleGraph) / parseInt(CurrentStage?.total_limit)) * 100;

    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)
    let remaining = parseInt(
      parseInt(CurrentStage?.total_limit) -
        (parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken) >
        parseInt(CurrentStage?.total_limit)
          ? parseInt(CurrentStage?.total_limit)
          : parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken))
    );
    let sold = TotalToken - BalanceOfNbonk;

    setPercentage(
      parseFloat((sold / (BalanceOfNbonk + sold)) * 100).toFixed(2)
    );

    // setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  }, [TotalSaleInStage, CurrentStage, saleGraph, BonusToken]);
  const DefaultChain = {
    ETH: "ETH",
    BNB: "BNB",
  };

  useEffect(() => {
    if (address)
      getTotalTokenOfwallet(address)
        .then((totlatoke) => {
          settotalToken(totlatoke.data ?? 0);
        })
        .catch(() => {});
  }, [address]);
  const [TotalRaised, setTotalRaised] = useState();

  useEffect(() => {
    let sum = 0;
    getSumOfCurrencies()
      .then((res) => {
        setTotalRaised(res.data.totalraised);
      })
      .catch(() => {});
  }, []);
  const bgVideoRef = useRef(null);
  const videoSource = BannerVideo; // Replace with the actual path to your video file

  useEffect(() => {
    // Set the playback rate to 0.5 for half speed (adjust as needed)
    if (bgVideoRef.current) {
      bgVideoRef.current.playbackRate = 0.5;
    }
  }, []);

  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  useEffect(() => {
    // Set up an interval to change the icon every 2 seconds
    const intervalId = setInterval(() => {
      // Update the current icon index
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % iconsArray.length);
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once on mount

  useEffect(() => {
    const fetchData = () => {
      GetBalanceOfNBonk(ContractAddressICO)
        .then((result) => {
          setBalanceOfNbonk(result / web3Value);
        })
        .catch((err) => console.error(err));

      GetBalanceOfUsdt(ContractAddressICO)
        .then((result) => {
          setTotalraised(result / 10 ** 18);
        })
        .catch((err) => console.error(err));
    };

    fetchData(); // Call immediately on mount

    const interval = setInterval(fetchData, 5000); // Call every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <>
      <header className="container-fluid">
        <Row>
          <Container fluid className="zindex">
            <Row>
              <Col xl="12" lg="12">
                <div className="landing-home">
                  <div className="txt-cntnr">
                    <div
                      onClick={() => {
                        setSignupShow(true);
                      }}
                    >
                      <h1>
                        Not a <span>Dog</span>, Not a <span>Cat</span> — It's
                        Just <span>NOTBONK</span>— Our Chuckle Champion{" "}
                        <span>Meme token!</span>
                      </h1>
                    </div>

                    <div
                      onClick={() => {
                        setSignupShow(true);
                      }}
                      style={{ width: "100%", height: "100%" }}
                    ></div>
                  </div>
                  <div className="stage-post-container">
                    <Col
                      xl="12"
                      lg="12"
                      md="12"
                      style={
                        !isDisconnected && !isConnecting
                          ? { display: "none" }
                          : {}
                      }
                      className="text-center"
                    >
                     
                      <div
                        className="stage-post"
                        onClick={() => {
                          setSignupShow(true);
                        }}
                      >
                       
                        <div className="img-pnl">
                        <h4 className="mt-0 font-weight-bold">
                            Stage 2
                          </h4>

                          <CircularProgressbar
                            value={percentage / 100}
                            maxValue={1}
                            text={`${percentage}%`}
                          />
                          {/* <img src={sold} alt="Data" /> */}
                        </div>
                        <div className="txt-pnl mt-4">
                          <div className="flex-div">
                            <p>Tokens remaining:</p>
                            <span>
                              {parseFloat(
                                TotalToken - (TotalToken - BalanceOfNbonk)
                              ).toFixed(2)}
                            </span>
                            {/* <span>
                     {
                     parseFloat(parseInt(CurrentStage?.total_limit) -parseInt(TotalSaleInStage ?? 0)  )?.toString().replace(CommasRegex, ',')  

                     }
                        </span> */}
                          </div>
                          <div className="flex-div">
                            <p> NBONK price in USD:</p>
                            <span>
                              {PriceOftkoen}{" "}
                              <img src={icontether1} alt="tether" />
                            </span>
                          </div>

                          <hr />
                          <div className="flex-div">
                            <p>Tokens Sold In Total:</p>
                            <span>
                              {parseFloat(TotalToken - BalanceOfNbonk).toFixed(
                                2
                              )}
                            </span>
                          </div>
                          <div className="flex-div">
                            <p>Total Raised:</p>
                            <span>$ {Totalraised}</span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="apply-code-pnl">
                        <div className="apply-code-pnl-inner">
                          <Form>
                            <div
                              className="input-cntnr"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                value={Promocode}
                                onChange={(e) => {
                                  let temp = e.target.value.split("ref=");
                                  SetPromocode(
                                    temp[1]
                                      ? temp[1].split("&")[0]
                                      : e.target.value
                                  );
                                }}
                                autoComplete="off"
                                type="text"
                                placeholder="Enter Referral CODE here"
                              />
                            </div>
                          </Form>
                          <a
                            onClick={() => {
                              if (Promocode) setSignupShow(true);
                            }}
                            className="reg-btn br-radous trans"
                            href={void 0}
                          >
                            Apply Code
                          </a>
                        </div>
                      </div> */}
                      <div className="trading-promo">
                        <p>
                          Trading will be live on <span>1st September</span>
                        </p>
                      </div>
                      <ul
                        className="footer-social-list Main-icon"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "40px",
                          marginTop: "20px",
                        }}
                      >
                        <li>
                          <Link target="_blank" to={twitterIcon}>
                            <img src={twitter} alt="twitter" />
                          </Link>
                        </li>
                        <li>
                          <Link target="_blank" to={DiscordIcon}>
                            <img src={Discord} alt="Discord" />
                          </Link>
                        </li>
                        {/* <li>
                    <Link target="_blank" to={InstagramUrl}>
                    <img src={gitt} alt="Git" />
                    </Link>
                  </li> */}
                        <li>
                          <Link target="_blank" to={teleGramUrl}>
                            <img src={telegram} alt="telegram" />
                          </Link>
                        </li>

                        <li>
                          <Link target="_blank" to={facebookIcon}>
                            <img src={facebook} alt="telegram" />
                          </Link>
                        </li>

                        <li>
                          <Link target="_blank" to={InstagramUrl}>
                            <img src={instagram} alt="telegram" />
                          </Link>
                        </li>

                        <li>
                          <Link target="_blank" to={linkedinIcon}>
                            <img src={linkedin} alt="telegram" />
                          </Link>
                        </li>
                      </ul>
                      <div
                        onClick={() => {
                          navigate("/Dashboard");
                        }}
                        className="btn-cntnr"
                      >
                        <div className="mb-1">
                          <Link
                            id='PreSale-btn-dash'
                            className="reg-btn br-radous gradient mt-4 mb-1"
                            onClick={() => navigate("/Dashboard")}
                            to={void 0}
                          >
                            Join Presale
                          </Link>
                          <br />
                          {/* <Link className="simple-link" to="/HowToBuy">
                            How to Join?
                          </Link> */}
                        </div>
                      </div>
                    </Col>

                    <Col
                      xl="12"
                      lg="12"
                      md="12"
                      style={
                        !isDisconnected && !isConnecting
                          ? { display: "inline-block" }
                          : {}
                      }
                      className="text-center displayNone"
                    >    
                      <div className="stage-post">
                 
                        <div className="img-pnl">
                        <h4 className="mt-0 font-weight-bold">
                            Stage 2
                          </h4>

                          <CircularProgressbar
                            value={percentage / 100}
                            maxValue={1}
                            text={`${percentage}%`}
                          />
                        </div>
                        <div className="txt-pnl mt-4">
                          <div className="flex-div">
                            <p>Tokens remaining :</p>
                            <span>
                              {parseFloat(
                                TotalToken - (TotalToken - BalanceOfNbonk)
                              ).toFixed(2)}{" "}
                            </span>
                            {/* <span>
                      {
                          parseFloat(parseInt(CurrentStage?.total_limit) -parseInt(TotalSaleInStage ?? 0)  )?.toString().replace(CommasRegex, ',')  

                      }
                    </span> */}
                          </div>
                          <div className="flex-div">
                            <p> NBONK price in current stage:</p>
                            <span className="yellow-color">
                              {PriceOftkoen}
                              <img src={icontether1} alt="tether" />
                            </span>
                          </div>
                          {/* <div className="flex-div">
                            <p>Token price in the next stage:</p>
                            <span>{CurrentStage?.futuretokenprice}</span>
                          </div> */}
                          <hr />
                          <div className="flex-div">
                            <p>Tokens Sold In Total:</p>
                            <span>
                              {parseFloat(TotalToken - BalanceOfNbonk).toFixed(
                                2
                              )}
                            </span>
                          </div>
                          <div className="flex-div">
                            <p>Total Raised:</p>
                            <span>$ {Totalraised}</span>
                          </div>
                        </div>
                      </div>

                      <div className="spacer-20" />
                      <div className="spacer-20" />

                      {/* <Link className="simple-link" to="/HowToBuy">
                        How to Join?
                      </Link> */}
                    </Col>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </header>
    </>
  );
}
export default Header;
