export const LatestBlock = 'LatestBlock'

export const   CurrentStage = "CurrentStage"

export const TotalSaleInStage ="TotalSaleInStage"

export const OverAllSaleToken =  "OverAllSaleToken"

export const Promocode =  "Promocode"

export const UserDetail =  "UserDetail"

export const SelectedCurrency =  "SelectedCurrency"

export const Selectedvalue =  "Selectedvalue"

export const Promotioncode = "Promotioncode"

export const showTransaction = 'showTransaction'


export const NavBaRSignUp = 'NavBaRSignUp'

export const PriceOftkoen =  "PriceOftkoen"

export const ConnectedAddress = 'ConnectedAddress'