
import React, { useRef, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.scss";
import { Route, Routes, useNavigate } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import TransactionHistory from "./Pages/TransactionHistory";
import TermsConditions from "./Pages/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
// import Faq from "./Pages/Faq";
// Admin Panel
import Dashboard from "./Pages/AdminPanel/Dashboard";
import AccountActivities from "./Pages/AdminPanel/AccountActivities";
import MyProfileDetails from "./Pages/AdminPanel/MyProfileDetails";
import MyToken from "./Pages/AdminPanel/MyToken";
import Transactions from "./Pages/AdminPanel/Transactions";
import PurchaseToken from "./Pages/AdminPanel/PurchaseToken";
import Referral from "./Pages/AdminPanel/Referral";
import WalletTransaction from "./Pages/AdminPanel/WalletTransactions";
import Faq from "./Pages/Faq";
// Admin Panel

function NotFound() {
  return (
    <div className="pagenotfound">
      <h1>404 - Page Not Found</h1>
    </div>
  );
}

function Router() {
  const [showFooter, setShowFooter] = useState(true);
  const [showNavBar, setShowNavBar] = useState(true);
  const [SignupShow, setSignupShow] = useState(false);
  const [forgetPassword, setforgetPassword] = useState(false);
  const [loginShow, setloginShow] = useState(false);
  const isJwtTokenPresent = localStorage.getItem("JwtToken");
  const [ctaModal, setctaModal] = useState(false);

  const Navigate = useNavigate();

  const featuredSectionRef = useRef(null);

  const scrollToFeatured = () => {
    // Navigate to the root path
    Navigate("/");

    // Scroll to the featured section after a short delay (adjust as needed)
    setTimeout(() => {
      if (featuredSectionRef.current) {
        featuredSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed
  };

  const RewardSectionRef = useRef(null);

  const RewardToFeatured = () => {
    Navigate("/");

    // Scroll to the featured section after a short delay (adjust as needed)
    setTimeout(() => {
      if (RewardSectionRef.current) {
        RewardSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed

    // Scroll to the featured section
  };
  const TokenSectionRef = useRef(null);

  const TokenToFeatured = () => {
    Navigate("/");

    // Scroll to the featured section after a short delay (adjust as needed)
    setTimeout(() => {
      if (TokenSectionRef.current) {
        TokenSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed

    // Scroll to the featured section
    // Scroll to the featured section
  };
  const RoadSectionRef = useRef(null);

  const RoadToFeatured = () => {
    Navigate("/");

    // Scroll to the featured section after a short delay (adjust as needed)
    setTimeout(() => {
      if (RoadSectionRef.current) {
        RoadSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed

    // Scroll to the featured section
  };

  const FAQeSectionRef = useRef(null);

  const FaqToFeatured = () => {
    // Scroll to the featured section
    FAQeSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {showNavBar && (
        <NavBar
          loginShow={loginShow}
          setctaModal={setSignupShow}
          ctaModal={SignupShow}
          setloginShow={setloginShow}
          scrollToFeatured={scrollToFeatured}
          RewardToFeatured={RewardToFeatured}
          TokenToFeatured={TokenToFeatured}
          setSignupShow={setctaModal}
          RoadToFeatured={RoadToFeatured}
          FaqToFeatured={FaqToFeatured}
          SignupShow={ctaModal}
          setShowFooter={setShowFooter}
          forgetPassword={forgetPassword}
        />
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Home
              featuredSectionRef={featuredSectionRef}
              RewardSectionRef={RewardSectionRef}
              TokenSectionRef={TokenSectionRef}
              RoadSectionRef={RoadSectionRef}
              FAQeSectionRef={FAQeSectionRef}
              setShowFooter={setShowFooter}
              setShowNavBar={setShowNavBar}
              setSignupShow={setSignupShow}
              SignupShow={SignupShow}
            />
          }
        />

        <Route
          path="/Dashboard"
          element={

            <Dashboard
              setShowFooter={setShowFooter}
              setShowNavBar={setShowNavBar}
            />

          }
        />
            <Route path="/TermsConditions" element={<TermsConditions />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        {/* <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogDetail />} /> */}

        {/* AdminPanel */}

        {/* 404 Page */}
        <Route path="*" element={<NotFound />} />
        {/* 404 Page */}
      </Routes>
      {showFooter && (
        <Footer
          scrollToFeatured={scrollToFeatured}
          RewardToFeatured={RewardToFeatured}
          TokenToFeatured={TokenToFeatured}
          setSignupShow={setSignupShow}
          RoadToFeatured={RoadToFeatured}
          FaqToFeatured={FaqToFeatured}
        />
      )}
    </>
  );
}

export default Router;