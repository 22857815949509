import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
function TermsConditions() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Terms And Conditions Starts Here */}
      <section className="terms-policy container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h1>Terms and Conditions</h1>
              </Col>
              <Col xl="12" lg="12" md="12">
              <h3>Acceptance of Terms</h3>
<p>
    By accessing or using NOTBONK's website and services (collectively, the "Service"), you agree to be bound by these Terms and Conditions ("Terms"). If you disagree with any part of the Terms, you may not access or use our Service.
</p>

<h3>Changes to Terms</h3>
<p>
    NOTBONK reserves the right to modify these Terms at any time. We will notify users of any material changes via our website or email. Your continued use of the Service after such modifications constitutes your acceptance of the new Terms.
</p>

<h3>Intellectual Property</h3>
<p>
    All content, features, and functionality on the Service are owned by NOTBONK and are protected by copyright, trademark, and other intellectual property laws. You may not copy, distribute, or use our content without prior written permission from NOTBONK.
</p>

<h3>User Accounts</h3>
<p>
    Where required, you agree to provide accurate and complete information when creating an account. You are solely responsible for maintaining the confidentiality of your account and password. You must immediately notify NOTBONK of any unauthorized use of your account.
</p>

<h3>Third-Party Services</h3>
<p>
    Our Service may contain links to third-party websites or services. NOTBONK is not responsible for the content or practices of any linked third-party sites. Use of third-party services is at your own risk.
</p>

<h3>Disclaimer of Warranties</h3>
<p>
    The Service is provided on an "AS IS" and "AS AVAILABLE" basis. NOTBONK makes no warranties, expressed or implied, regarding the operation of the Service or the information, content, or materials included.
</p>

<h3>Limitation of Liability</h3>
<p>
    NOTBONK shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the Service.
</p>

<h3>Termination</h3>
<p>
    NOTBONK may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason, including breach of these Terms. Upon termination, your right to use the Service will immediately cease.
</p>

<h3>Governing Law</h3>
<p>
    These Terms shall be governed by and construed in accordance with the applicable laws of Jurisdiction, without regard to its conflict of law provisions.
</p>

<h3>Changes to Service</h3>
<p>
    NOTBONK reserves the right to withdraw or amend our Service, and any service or material we provide via the Service, in our sole discretion without notice.
</p>

<h3>Prohibited Uses</h3>
<p>
    You may use our Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:
</p>
<ul>
    <li>In any way that violates any applicable law or regulation.</li>
    <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
    <li>To impersonate or attempt to impersonate NOTBONK, a NOTBONK employee, another user, or any other person or entity.</li>
</ul>

<h3>Indemnification</h3>
<p>
    You agree to defend, indemnify, and hold harmless NOTBONK, its officers, directors, employees, agents, and affiliates from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorney's fees) arising from:
</p>
<ul>
    <li>Your use of NOTBONK's services, products, or website;</li>
    <li>Your violation of these Terms of Service or any applicable laws, regulations, or third-party rights;</li>
    <li>Any content you submit to NOTBONK that infringes or violates the rights of a third party.</li>
</ul>
<p>
    This indemnification obligation shall survive termination of these Terms of Service and your use of NOTBONK’s services.
</p>

<h3>Severability</h3>
<p>
    If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
</p>

<h3>Entire Agreement</h3>
<p>
    These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service.
</p>

<p>
    By using NOTBONK's services, you acknowledge that you have read and understood these Terms and agree to be bound by them.
</p>

                <div className="spacer-40" />
              </Col>
              <Col xl="12" lg="12" md="12" className="text-center">
                <Link className="reg-btn br-radius" to="/">
                  Back To Homepage
                </Link>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Terms And Conditions Ends Here */}
    </>
  );
}
export default TermsConditions;
