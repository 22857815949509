import { ToastContainer } from 'react-toastify';
import Layout from './Layout';
import 'react-toastify/dist/ReactToastify.css';
import { useContext, useEffect } from 'react';
import { GetAllStageTransactions, GetCurrentStage, GetCurrentStageTransactions } from './Api';
import UserContest from './ContextAPI/UserContest';
import GlobalErrorBoundary from './GlobalHandler';
import { createWeb3Modal } from '@web3modal/wagmi/react';

import { FE_URL, NoStageActive, WalletIcon, projectId } from './Constants';
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { arbitrum,  mainnet, polygon, optimism ,bsc } from 'viem/chains'
import { publicProvider } from 'wagmi/providers/public'
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'
import { CoinbaseWalletConnector  } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

function App() {
  const { SetTotalSaleInStage, SetCurrentStage, SetOverAllSaleToken } = useContext(UserContest);

  useEffect(() => {
    GetCurrentStage().then((stage) => {
      SetCurrentStage(stage);
      if (!stage?.id) {
        SetCurrentStage(NoStageActive);
      }
      GetCurrentStageTransactions(stage.id).then((data) => {
        SetTotalSaleInStage(data.data.total_tokens_purchased);
      }).catch(() => {});

    }).catch(() => {
      SetCurrentStage(NoStageActive);
    });
    GetAllStageTransactions().then((data) => {
      SetOverAllSaleToken(data.data.total_tokens_purchased);
    }).catch(() => {});

  }, []);

  // Configure chains and providers
  const { chains, publicClient } = configureChains(
    [bsc],
    [publicProvider(),walletConnectProvider({ projectId })]
  );

  // Metadata for Web3Modal
  const metadata = {
    name: 'Notbonk',
    description: 'A decentralized algorithmic crypto trading platform',
    url: FE_URL,
    icons: [WalletIcon],
  };

  // Create wagmiConfig
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
      new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata ,shimDisconnect: true } }),
      new EIP6963Connector({ chains }),
      new InjectedConnector({ chains, options: { shimDisconnect: true } }),
      new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
    ],
    publicClient
  })

  // Create modal
  createWeb3Modal({
    wagmiConfig,
    projectId,
    chains,
    themeMode: 'light', // Set the theme mode to 'light'
    themeVariables: {
      '--w3m-accent': '#31A7FB', // Set the accent color
      '--wui-color-accent-100': 'white', // Set the UI accent color
      '--wui-gray-glass-010': '0px solid #31A7FB',
    },
  });

  // Debugging logs


  return (
    <>
      <GlobalErrorBoundary>
        <WagmiConfig config={wagmiConfig}>
          <Layout />
        </WagmiConfig>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme={'light'}
        />
      </GlobalErrorBoundary>
    </>
  );
}

export default App;
