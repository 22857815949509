import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { ContractAddressICO, NotBonkAddress, SWAPTOEKNADDRESS } from "../Constants";
import { FaCopy } from "react-icons/fa";
import { Notifications } from "./Toast";

const copyToClipboard = (ADDRESS) => {
  navigator.clipboard
    .writeText(ADDRESS)
    .then(() => {
      Notifications("success", "Address copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy address: ", err);
    });
};

function Frequentlyquestion() {
  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            What is NOTBONK? <span></span>
          </Accordion.Header>
          <Accordion.Body>
            NOTBONK is a unique meme coin launched on the Binance Smart Chain.
            Unlike traditional cryptocurrencies, NOTBONK embraces its identity
            as a lighthearted, prankster coin with no serious protocols or token
            utility. It's the ultimate twist in the world of meme coins,
            designed to bring humor and fun to the crypto community.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            How can I buy NOTBONK?
            <span></span>
          </Accordion.Header>
          <Accordion.Body>
            Buying NOTBONK is easy! Simply click on the 'Join Pre-sale' button,
            connect your wallet, and purchase NOTBONK tokens.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            On which decentralized exchange will NOTBONK be launched?{" "}
            <span></span>
          </Accordion.Header>
          <Accordion.Body>
            NOTBONK will be launched on Uniswap, one of the leading
            decentralized exchanges.
            {/* The contract address for NOTBONK is: {ContractAddressICO}{" "}
            <FaCopy
              style={{ cursor: "pointer" }}
              onClick={() => {
                copyToClipboard(ContractAddressICO);
              }}
            />
            <br />
            (Important: Always verify the contract address on our official
            website or social media channels to avoid scams) */}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            What is the contract address of your token? <span></span>
          </Accordion.Header>
          <Accordion.Body>
            The contract address for NOTBONK is: { NotBonkAddress }     <FaCopy
              style={{ cursor: "pointer" }}
              onClick={() => {
                copyToClipboard(NotBonkAddress);
              }}
            />  <br />
            (Important: Always verify the contract address on our official
            website or social media channels to avoid scams)
            {/* The pair address for NOTBONK Token is: {NotBonkAddress}{" "}
            <FaCopy
              style={{ cursor: "pointer" }}
              onClick={() => {
                copyToClipboard(NotBonkAddress);
              }}
            />
            <br />
            (Note: This will be available once the token is listed on Uniswap). */}
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>
            What is the pair address of NOTBONK token? <span></span>
          </Accordion.Header>
          <Accordion.Body>
            The pair address for NOTBONK Token is: {SWAPTOEKNADDRESS}
            <FaCopy
              style={{ cursor: "pointer" }}
              onClick={() => {
                copyToClipboard(SWAPTOEKNADDRESS);
              }}
            /> 
            <br />
             (Note:
            This will be available once the token is listed on Uniswap).
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>
            What is the launch date of the NOTBONK token? <span></span>
          </Accordion.Header>
          <Accordion.Body>
            The NOTBONK token is scheduled to launch on September 1st.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>
            What is the Utility of NOTBONK Coin? <span></span>
          </Accordion.Header>
          <Accordion.Body>
            The charm of NOTBONK lies in its simplicity and humor. It has zero
            integrations with DeFi, gaming, NFTs, or wallets. There are no
            complicated protocols or token utilities to worry about. NOTBONK is
            intended to bring a truly unique and entertaining addition to your
            collection.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>
            Why totally NOTBONK, why not any other coin? <span></span>
          </Accordion.Header>
          <Accordion.Body>
            NOTBONK stands out in the crowded world of cryptocurrencies and Meme
            coins with its rebellious and humorous approach. If you’re looking
            for a break from the serious, utility-driven coins, get NOTBONK and
            become a part of a community that values fun and believes in making
            it extraordinary.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>What are the benefits of NOTBONK? <span></span></Accordion.Header>
          <Accordion.Body>
          With NOTBONK, you get to be part of a fun and unique journey, enjoying the lighter side of the crypto world without any of the usual complexities. Moreover, NOTBONK is available on Uniswap, one of the leading decentralized exchanges, and its smart contract is deployed on the Binance Smart Chain, ensuring secure and seamless transactions.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Frequentlyquestion;
