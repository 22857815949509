import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import icontether from "../Assets/img/Icons/coins (2).png";
import minilogo from "../Assets/img/Icons/icon-logo.png";
import iconetherium from "../Assets/img/Icons/icon-etherium.png";
import chart from "../Assets/images/chart1.png";
import bg from "../Assets/images/vx-bg.png";
function Tokenomics() {
  return (
    <>
      <h2 className="text-uppercase font-weight-normal">
      Notbonk
        <br />
        <span>Tokenomics </span>
      </h2>
      <div className="spacer-30" />
      <div className="vx-txt-pnl">
        <div className="bg-layer" style={{ backgroundImage: `url(${bg})` }} />
        <Row>
          <Col xl="12" lg="12" md="12" className="text-center">
            <img src={chart} alt="Chart" />
            <div className="spacer-30"/>
          </Col>
        </Row>
      </div>
     
    </>
  );
}
export default Tokenomics;
