import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import { Notifications } from "../Components/Toast";
function PrivacyPolicy() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  const copyToClipboard = (NotBonkAddress) => {
    navigator.clipboard
      .writeText(NotBonkAddress)
      .then(() => {
        Notifications("success", "Email copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy address: ", err);
      });
  };
  return (
    <>
      {/* Privacy Policy Starts Here */}
      <section className="terms-policy container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h1>Privacy Policy</h1>
              </Col>
              <Col xl="12" lg="12" md="12">
                
                <h3>Introduction</h3>
                <p>
    This Privacy Policy (“Policy”, together with our Terms of Use) explains how NOTBONK collects, uses, discloses, and protects personal information obtained from visitors to our website(s).
</p>
<p>
    By visiting our website(s), you agree to the practices outlined in this Policy, including the collection and processing of any personal information as described below. We may update this Policy periodically, so please review it occasionally to ensure your agreement with any changes. Your continued use of our website(s) signifies your acceptance of, and agreement to, any updates.
</p>
<h3>How We Collect Information About You</h3>
<p>
    We gather information through your interactions with our website(s), such as when you use our services, contact us via email or web forms, or subscribe to newsletters. The types of information we collect include:
</p>
<h3>Information You Provide</h3>
<p>
    Personal details you provide by filling out forms on our website(s) or through email or live chat correspondence. This may include your name, email address, phone number, and language preferences. By providing this information, you consent to its use in accordance with this Policy.
</p>
<h3>Information We Collect Automatically</h3>
<p>
    When you visit our website(s), we automatically collect data such as details about your visit, browsing activities, and site usage patterns. This information may be combined with data you provide directly.
</p>
<h3>Information We Receive from Other Sources</h3>
<p>
    We may obtain information about you from other websites operated by NOTBONK or our partners. Additionally, we collaborate with third parties (including contractors, project partners, service providers, and analytics providers) who may share information about you with us. This data may supplement information you provide.
</p>
<h3>How Your Information is Used</h3>
<p>
    We use personal information for the following purposes:
</p>
<ul>
    <li>To provide you with personalized communications based on your interests and preferences, including information about campaigns, activities, and events related to NOTBONK or our partners.</li>
    <li>To evaluate and improve our services, including analyzing website traffic, assessing marketing effectiveness, and identifying the most effective channels for communication.</li>
    <li>For analytics and profiling to generate aggregate reports, understand visitor behavior, and enhance user experience.</li>
    <li>To seek feedback on our services, notify you of changes to our terms of use, policies, or services, and engage you in surveys or comments.</li>
</ul>
<p>
    We regularly review data retention periods and retain personal information only as long as necessary for the purposes outlined above.
</p>
<h3>Who Has Access to Your Information</h3>
<p>
    We do not sell or rent your information to third parties for marketing purposes. However, we may share your data with third-party service providers, agents, subcontractors, and affiliated organizations to facilitate tasks and services on our behalf (e.g., processing information or sending emails). Any third-party service provider we engage is contractually obligated to maintain data security and refrain from using your information for their own marketing purposes.
</p>
<p>
    We take steps to ensure your privacy rights are protected.
</p>
<h3>Your Choices and Rights</h3>
<p>
    You have the right to instruct us not to process your personal information for marketing purposes. You can exercise this right by adjusting preferences on data collection forms or contacting us at:
</p>
<p>
    We do not contact you for marketing purposes unless you provide explicit consent. You can update your marketing preferences by emailing us at:
</p>
<h3>Updating Your Information</h3>
<p>
    Maintaining accurate information is important to us. If your email address or other details we hold are inaccurate or outdated, please email us at: contact@notbonk.xyz     <FaCopy
              style={{ cursor: "pointer" }}
              onClick={() => {
                copyToClipboard(' contact@notbonk.xyz ');
              }}
            /> <br />
    to update your information.
</p>
<h3>Security Measures</h3>
<p>
    We implement security measures to protect your personal information when provided to us.
</p>
<p>
    While non-sensitive details (e.g., email addresses) may be transmitted unencrypted over the internet, no transmission method is entirely secure. We strive to protect your information, but transmission is at your own risk. Upon receiving your data, we employ robust security protocols to safeguard it within our systems.
</p>
<h3>Profiling</h3>
<p>
    We may analyze your personal information to create a profile reflecting your preferences and interests, enabling us to provide tailored information. External data sources may enrich this analysis to enhance our service delivery.
</p>
<h3>Use of Cookies</h3>
<p>
    NOTBONK’s website(s) use cookies to distinguish you from other visitors, enhancing your browsing experience. Cookies are small text files stored on your device by a server, allowing the website to recognize you upon return visits.
</p>
<p>
    Cookies may be used to personalize content, provide social media features, and analyze site traffic, improving our service. By continuing to use our website(s), you consent to our use of cookies. We share cookie usage information with social media and analytics partners, who may combine it with other data you’ve provided or they’ve collected.
</p>
<p>
    You can manage or withdraw your consent regarding cookies through our website’s cookie declaration. This consent applies to domains including notbonk.xyz.
</p>
<h3>Types of Cookies We Use</h3>
<ul>
    <li>Strictly Necessary Cookies: Essential for website functionality, enabling basic features like page navigation and secure areas.</li>
    <li>Targeting Cookies: Set by advertising partners to track visitors across websites and display personalized ads, based on unique browser and device identifiers.</li>
    <li>Performance Cookies: Statistical tools counting visits and traffic sources to measure and enhance site performance. Data collected remains anonymous.</li>
</ul>
<h3>Links to Other Websites</h3>
<p>
    Our website(s) may contain links to external sites operated by other organizations. This Policy applies solely to NOTBONK’s website(s); we encourage you to review the privacy statements of linked sites. We are not responsible for the privacy policies and practices of external sites, even if accessed through links on our site.
</p>
<p>
    If redirected to our site from a third-party site, review the privacy policies of the site’s owners and operators.
</p>
<h3>Children Aged 16 or Under</h3>
<p>
    We prioritize protecting the privacy of children aged 16 or under. If you are under 16, obtain parental/guardian consent before sharing personal information with us.
</p>
<h3>Where We Store Your Personal Information</h3>
<p>
    Information collected may be transferred to and stored in countries outside the European Economic Area (EEA), or processed by staff operating outside the EEA who work for us or our partners. Data protection laws in some countries may not offer the same legal safeguards as those in the EEA. By submitting personal information, you consent to its transfer, storage, and processing. We take reasonable steps to ensure your data is handled securely and in accordance with this Policy.
</p>
<p>
    Internet transmission of information is not entirely secure. Although we endeavor to protect your personal data, we cannot guarantee its security during transmission. Upon receipt, we employ strict procedures and robust security measures to prevent unauthorized access.
</p>

                <div className="spacer-40" />
              </Col>
              <Col xl="12" lg="12" md="12" className="text-center">
                <Link className="reg-btn br-radius" to="/">
                  Back To Homepage
                </Link>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Privacy Policy Ends Here */}
    </>
  );
}
export default PrivacyPolicy;
